
import { defineComponent, ref, computed } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import TextField from '@/components/general/atoms/TextField.vue';
import { certifications } from '@/services/communities/types'
import { useRoute } from 'vue-router';
import { CommunityService } from '@/services/communities/CommunityServices';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';

export default defineComponent({
    emit: ["update:visible", "update", "change"],
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        items: {
            type: certifications,
            required: false
        },
    },
    setup(props, { emit }) {
        const CommunityServices = new CommunityService();
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const hasError = ref(false)
        const hasErrorNombre = ref<boolean>(false);
        const hasErrorMonth = ref<boolean>(false);
        const hasErrorYears = ref<boolean>(false);
        const showModalAlert = ref<boolean>(false);
        const showModal = ref<boolean>(props.visible)
        const certificationType = ref<string>(props.items?.certificationType || '')
        const year = ref<string>(props.items?.date || '')
        const isFieldsFilled = computed(() => {
            return !!certificationType.value && !!year.value;
        });

        const save = async () => {
            if (props.action === 'create') {
                const cert = {
                    certificationType: certificationType.value,
                    date: year.value,
                }
                console.log(communityId.value);
                await CommunityServices.createCertification(communityId.value, blockId.value, cert)
                showModalAlert.value = true;
            } else if (props.action === 'update' && props.items) {
                const certUpdate = {
                    certificationType: certificationType.value,
                    date: year.value,
                    blockId: blockId.value,
                }
                await CommunityServices.updateCertification(communityId.value, blockId.value, props.items.id, certUpdate)
                showModalAlert.value = true;
            }
        };

        const handleCancel = () => {
            showModalAlert.value = false;
            closeModal()
        };

        const handleAccept = () => {
            showModal.value = false;
            showModalAlert.value = false;
            emit('change')
            closeModal()
        };

        const closeModal = () => {
            showModalAlert.value = true
            showModal.value = false;
            emit('closeModal');
        };

        return {
            showModal, save, closeModal, certificationType, year, hasErrorNombre, hasErrorMonth, hasErrorYears, hasError, showModalAlert, isFieldsFilled,
            handleCancel, handleAccept
        };
    },
    components: { Modal, TextField, ModalAlert},
});
