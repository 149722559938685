
export default {
    props: {
        totalSteps: {
            type: Number,
            required: true
        },
        stepActual: {
            type: Number,
            required: true
        },
        isSimulacrum: {
            type: Boolean,
            required: true
        }
    }
};
