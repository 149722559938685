import { AuthenticatedService } from "../auth/AuthenticatedServices";
import { getResponse } from "../utils";
import { getEnvConfig } from "@/configs/UrlConfig";
import { Event, EventInstruction, EventContainer } from "./type";
import { Response } from "../types";


export class EventService extends AuthenticatedService {

  async getEventAll(communityId: number): Promise<Response<EventContainer[]>> {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/community-events`;
    return getResponse(url, this.token, "GET", undefined, undefined, Event);
  }

  async getEventInstruction(communityId: number, eventType: string): Promise<Response<EventInstruction[]>> {
    const params = {
      event_type :eventType
    }
    const url = `${getEnvConfig().apiURL}/events/all-by-type`;
    return getResponse(url, this.token, "POST", params, undefined, EventInstruction);
  }

}