import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full" }
const _hoisted_2 = { class: "flex pt-2 pb-2 pl-4 justify-between gap-2" }
const _hoisted_3 = { class: "mt-2 items-center" }
const _hoisted_4 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "flex pt-2 pl-4 items-center justify-center gap-2" }
const _hoisted_11 = { class: "flex flex-row gap-1" }
const _hoisted_12 = { class: "flex-col md:flex-row w-full md:w-full p-4 h-full items-stretch flex flex-wrap justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_CardEmergency = _resolveComponent("CardEmergency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbRoutes, (route, index) => {
          return (_openBlock(), _createElementBlock("span", { key: index }, [
            (index !== _ctx.breadcrumbRoutes.length - 1)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: route.path
                }, {
                  default: _withCtx(() => [
                    (index === _ctx.breadcrumbRoutes.length - 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(route.name), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(route.name), 1))
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  (index === _ctx.breadcrumbRoutes.length - 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(route.name), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(route.name), 1))
                ])),
            (index !== _ctx.breadcrumbRoutes.length - 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, " - "))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("aside", _hoisted_10, [
        _createElementVNode("button", {
          class: "flex border-2 border-success text-success rounded-lg p-1 hover:bg-green-100",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
        }, [
          _createElementVNode("strong", _hoisted_11, [
            _createVNode(_component_mdicon, { name: "backup-restore" }),
            _createTextVNode(" Volver a Consultas ")
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_12, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.committeDate, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "w-full md:w-1/3 p-2 flex"
        }, [
          _createVNode(_component_CardEmergency, {
            size: 15,
            styleCard: 'bg-success bg-gradient-to-b from-cardMenu ',
            onClick: ($event: any) => (_ctx.goEventModal(item.id)),
            class: "cursor-pointer flex-grow",
            figure: item.figure,
            title: item.name
          }, null, 8, ["onClick", "figure", "title"])
        ]))
      }), 128))
    ])
  ]))
}