import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e6e1910"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "fixed left-0 top-0 z-50 w-full h-full grid content-center bg-black/50 transition-opacity"
}
const _hoisted_2 = { class: "text-2xl font-semibold text-success uppercase my-5 text-center" }
const _hoisted_3 = { class: "p-5" }
const _hoisted_4 = { class: "grid mt-3 p-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        (_ctx.visible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([_ctx.background, "md:w-xl md:shadow-lg md:rounded-lg m-auto p-6 relative transition-all w-5/6 modal-container h-[100%] overflow-y-auto"]),
                id: "dialog"
              }, [
                _createVNode(_component_mdicon, {
                  class: "absolute right-5 top-5 text-gray-400 hover:text-gray-900 cursor-pointer",
                  name: "close",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
                }),
                _renderSlot(_ctx.$slots, "header", {}, () => [
                  _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1)
                ], true),
                _createElementVNode("section", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                _createElementVNode("footer", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                ])
              ], 2)), [
                [_directive_click_outside, _ctx.clickOutside]
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}