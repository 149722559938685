
import { defineComponent, ref } from 'vue';
import CardEmergency from '@/components/general/molecules/CardEmergency.vue';
import { EventService } from '../../services/events/eventServices';
import { Event } from '@/services/events/type';
import EventModal from '@/components/events/molecules/EventModal.vue'
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    setup() {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const route = useRoute();
        const eventService = new EventService();
        const eventData = ref<Event[]>([]);
        const showEventModal = ref<boolean>()
        const eventSelect = ref<object>();
        const loading = ref(false)
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0)
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: ``, name: 'Eventos' },
        ]

        const init = async () => {
            loading.value = true
            const response = await eventService.getEventAll(communityId.value);

            const eventContainers = response.data;
            const events = eventContainers.map(container => container.event)
            const modifiedEvents = events.map(event => {
                event.icon = event.eventType === 'GAS_LEAK' ? 'pipe-wrench' : event.eventType === 'WATER_CUT' ? 'water-off'
                    : event.eventType === 'WATER_PIPE_BURST' ? 'pipe-disconnected' : event.eventType === 'UNIT_BURGLARY' ? 'transit-transfer'
                        : event.eventType === 'MOVING_SERVICE' ? 'bed-double' : event.eventType === 'NOISE_DISTURBANCE' ? 'bell-ring'
                            : event.eventType === 'SUSPECT_DETECTION' ? 'police-badge' : event.eventType === 'ELEVATOR_FAILURE' ? 'elevator-passenger'
                                : 'flash'
                event.title = event.eventType === 'GAS_LEAK' ? 'Fuga de Gas' : event.eventType === 'WATER_CUT' ? 'Corte de Agua'
                    : event.eventType === 'WATER_PIPE_BURST' ? 'Rotura de cañeria' : event.eventType === 'UNIT_BURGLARY' ? 'Robo de Departamento'
                        : event.eventType === 'MOVING_SERVICE' ? 'Mudanza' : event.eventType === 'NOISE_DISTURBANCE' ? 'Ruido Molesto'
                            : event.eventType === 'SUSPECT_DETECTION' ? 'Detección de Sospechoso' : event.eventType === 'ELEVATOR_FAILURE' ? 'Fallo del Ascensor'
                                : 'Corte Electrico'
                return event;
            });
            loading.value = false
            eventData.value = modifiedEvents;
        }

        const goEventModal = (item: any) => {
            eventSelect.value = item
            showEventModal.value = true
        }

        const handleCloseModal = () => {
            showEventModal.value = false
        }

        init()
        return { eventData, goEventModal, showEventModal, eventSelect, handleCloseModal, loading,breadcrumbRoutes,authUser,authUserCommunity }
    },
    components: { CardEmergency, EventModal }
})
