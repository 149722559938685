import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '@/views/auth/LoginView.vue';
import RegisterView from '@/views/auth/RegisterView.vue';
import HomeView from '@/views/menu/HomeView.vue';
import BlockList from '@/views/auth/BlockList.vue';
// menu principal
import Emergency from '@/views/principal/EmergencyView.vue';
import EventsView from '@/views/principal/EventsView.vue';
import QueriesView from '@/views/principal/QueriesView.vue';
// menu de tutoriales
import TutorialView from '@/views/principal/TutorialView.vue';
import VideoTutorial from '@/views/tutorial/VideoTutorialView.vue'
import FlatView from '@/views/tutorial/FlatView.vue'
import RegulationView from '@/views/tutorial/RegulationView.vue'
// menu de emergencia
import SimulacrumView from '@/views/Emergency/SimulacrumView.vue'
import FalseAlarmView from '@/views/Emergency/FalseAlarmView.vue'
// alertas
import AlertView from '@/views/Emergency/alert/AlertView.vue'
import AlertSuccessView from '@/views/Emergency/alert/AlertSuccessView.vue'
import AlertStepView from '@/views/Emergency/alert/AlertStepView.vue'
// instrucciones
import InstructionView from '@/views/Emergency/instruction/InstructionView.vue'
// Consultas
import questions from '@/components/queries/organisms/questions.vue'
import buildingBackground from '@/components/queries/organisms/buildingBackground.vue'
import contractorsView from '@/views/queries/contractorsView.vue'
import CommitteMenuView from '@/views/queries/CommitteMenuView.vue'
import fireInsurance from '@/views/queries/fireInsurance.vue'
import ResidentsView from '@/views/queries/censusMenu/ResidentsView.vue';
import CoOwnerView from '@/views/queries/censusMenu/CoOwnersView.vue';
// empadronamiento 
import DiminishedCapacitiesView from '@/views/queries/censusMenu/DiminishedCapacitiesView.vue';
import FireExtinguisherView from '@/views/queries/censusMenu/FireExtinguisherView.vue';
import GasCylindersView from '@/views/queries/censusMenu/GasCylindersView.vue';
import PetsView from '@/views/queries/censusMenu/PetsView.vue';
import VehiclesView from '@/views/queries/censusMenu/VehiclesView.vue';
import CertificationsView from '@/views/queries/censusMenu/CertificationsView.vue';
import CensusView from '@/views/queries/CensusView.vue'
// comite y servicio
import CommitteUserView from '../views/queries/committeeMenu/CommitteUserView.vue'
import PersonalServiceView from '../views/queries/committeeMenu/PersonalServiceView.vue'
import WorkersView from '../views/queries/committeeMenu/WorkersView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/community/:community/block/:id/home",
    name: "home",
    component: HomeView,
    props: true,
  },
  {
    path: "/block-list",
    name: "block-list",
    component: BlockList,
  },
  {
    path: "/community/:community/block/:id/events",
    name: "events",
    component: EventsView,
  },
  //Consultas
  {
    path: "/community/:community/block/:id/queries",
    name: "queries",
    component: QueriesView,
  },
  {
    path: "/community/:community/block/:id/queries/residents",
    name: "residents",
    component: ResidentsView
  },
  {
    path: "/community/:community/block/:id/queries/co-owners",
    name: "co-owners",
    component: CoOwnerView
  },
  {
    path: "/community/:community/block/:id/queries/questions",
    name: "questions",
    component: questions
  },
  {
    path: "/community/:community/block/:id/queries/buildingBackground",
    name: "buildingBackground",
    component: buildingBackground
  },

  {
    path: "/community/:community/block/:id/tutorial",
    name: "tutorial",
    component: TutorialView,
  },
  {
    path: "/falsa-alarma",
    name: "falsa-alarma",
    component: FalseAlarmView
  },
  {
    path: "/community/:community/block/:id/emergency/:emergencyId/simulacrum",
    name: "simulacrum",
    component: SimulacrumView
  },
  // consultas / empadronamiento
  {
    path: "/community/:community/block/:id/queries/census",
    name: "census",
    component: CensusView
  },
  {
    path: "/community/:community/block/:id/queries/census/diminished-capacities",
    name: "DiminishedCapacities",
    component: DiminishedCapacitiesView
  },
  {
    path: "/community/:community/block/:id/queries/census/fire-extinguisher",
    name: "FireExtinguisher",
    component: FireExtinguisherView
  },
  {
    path: "/community/:community/block/:id/queries/census/gas-cylinders",
    name: "GasCylinders",
    component: GasCylindersView
  },
  {
    path: "/community/:community/block/:id/queries/census/pets",
    name: "Pets",
    component: PetsView
  },
  {
    path: "/community/:community/block/:id/queries/census/vehicles",
    name: "Vehicles",
    component: VehiclesView
  },
  {
    path: "/community/:community/block/:id/queries/census/certifications",
    name: "Certifications",
    component: CertificationsView
  },
  // fin de empadronamiento
  {
    path: "/community/:community/block/:id/queries/contractors",
    name: "contractors",
    component: contractorsView
  },
  // menu de comite 
  {
    path: "/community/:community/block/:id/queries/committe",
    name: "committe",
    component: CommitteMenuView
  },
  {
    path: "/community/:community/block/:id/queries/committe/committe-user-list",
    name: "comite-usuario",
    component: CommitteUserView
  },
  {
    path: "/community/:community/block/:id/queries/committe/personal-service/workers",
    name: "trabajadores",
    component: WorkersView
  },
  {
    path: "/community/:community/block/:id/queries/committe/personal-service",
    name: "servicio-personal",
    component: PersonalServiceView
  },
  // {
  //   path: "/community/:community/block/:id/queries/committe/service-personal",
  //   name: "servicio-personal",
  //   component: ServicePersonalView
  // },
  // fin de menu comite 
  {
    path: "/community/:community/block/:id/queries/fireInsurance",
    name: "fireInsurance",
    component: fireInsurance
  },
  // alertas de emergencia
  // alertas y emergencia
  {
    path: "/community/:community/block/:id/emergency",
    name: "emergency",
    component: Emergency,
  },
  {
    path: "/community/:community/block/:id/emergency/:emergencyId/alert/:emergencyTypeId",
    name: "alert",
    component: AlertView
  },
  {
    path: "/community/:community/block/:id/emergency/:emergencyId/alert/:emergencyTypeId/success",
    name: "alert-success",
    component: AlertSuccessView,
  },
  {
    path: "/community/:community/block/:id/emergency/:emergencyId/alert/:emergencyTypeId/success/simulacrum",
    name: "alert-success-simulacrum",
    component: AlertSuccessView,
    props: { simulacrum: true }
  },
  {
    path: "/community/:community/block/:id/emergency/:emergencyId/alert/:emergencyTypeId/step/:stepId",
    name: "alert-step",
    component: AlertStepView,
  },
  {
    path: "/community/:community/block/:id/emergency/:emergencyId/alert/:emergencyTypeId/step/:stepId/simulacrum",
    name: "alert-step-simulacrum",
    component: AlertStepView,
    props: { simulacrum: 'true' }
  },
  // instrucciones
  {
    path: "/community/:community/block/:id/emergency/:emergencyId/alert/:emergencyTypeId/instruction",
    name: "instruction",
    component: InstructionView
  },

  // tutoriales
  {
    path: "/community/:community/block/:id/tutorial",
    name: "tutorial",
    component: TutorialView,
  },
  {
    path: "/community/:community/block/:id/tutorial/video",
    name: "movie",
    component: VideoTutorial,
  },
  {
    path: "/community/:community/block/:id/queries/reglamentos",
    name: "reglamentos",
    component: RegulationView,
  },
  {
    path: "/community/:community/block/:id/queries/planos",
    name: "planos",
    component: FlatView,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
