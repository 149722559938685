
import { defineComponent, ref } from 'vue';
import CardEmergency from '@/components/general/molecules/CardEmergency.vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    setup() {
        const committeDate = [
            { id: 1, name: 'Capacidades reducidas', figure: 'register/Accessible_forward' },
            { id: 2, name: 'Extintores', figure: 'register/fire_extinguisher' },
            { id: 3, name: 'Cilindros de gas', figure: 'register/gas_2' },
            { id: 4, name: 'Certificaciones', figure: 'register/certification' },
            { id: 5, name: 'Vehículos', figure: 'register/car' },
            { id: 6, name: 'Mascotas', figure: 'register/pets' },
        ]
        const router = useRouter();
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries`, name: 'Consultas' },
            { path: ``, name: 'Empadronamiento y registro' },
        ]
        const goEventModal = (id: any) => {
            switch (id) {
                case 1:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/census/diminished-capacities` });
                    break;
                case 2:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/census/fire-extinguisher` });
                    break;
                case 3:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/census/gas-cylinders` });
                    break;
                case 4:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/census/certifications` });

                    break;
                case 5:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/census/vehicles` });
                    break;
                case 6:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/census/pets` });
                    break;
            }
        }

        const back = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries` });
        }

        return {
            committeDate, breadcrumbRoutes, goEventModal, back
        }
    },
    components: { CardEmergency }
})
