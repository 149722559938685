import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col w-screen h-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.showNavbar)
            ? (_openBlock(), _createBlock(_component_NavBar, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass([{ 'ml-10 mr-10': _ctx.showTopBar }, "flex flex-col h-full"])
          }, [
            _createVNode(_component_router_view)
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}