
import { defineComponent, ref } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue';
import TextField from '@/components/general/atoms/TextField.vue';
import { useRoute } from 'vue-router';
import { TutorialVideo } from '@/services/tutorials/type'
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import InputFile from '@/components/general/molecules/InputFile.vue';
import { TutorialService } from '@/services/tutorials/TutorialsServices';
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        action: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        videoDate: {
            type: Object,
            required: false,
        },
        tutorialTypes: {
            type: String,
            required: false,
            default: "TRAINING_VIDEOS"
        }
    },
    setup(props, { emit }) {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const route = useRoute();
        const tutorialService = new TutorialService();
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0)
        const titleModal = ref<string>(props.tutorialTypes === 'TRAINING_VIDEOS' ? 'Video Tutorial' : props.tutorialTypes === 'REGULATIONS' ? 'Reglamento' : 'Planos')
        const file = ref(null);
        const videoTutorial = ref<TutorialVideo>({
            id: 0,
            blockId: blockId.value,
            name: '',
            description: '',
            tutorialType: props.tutorialTypes,
            document: null,
        });
        const hasError = ref(false)
        const hasErrorNombre = ref<boolean>(false);
        const hasErrorDescipcion = ref<boolean>(false);
        const message = ref<string>('');
        const showModalAdd = ref<boolean>(props.visible);
        const showModalAlert = ref<boolean>(false)

        const closeModal = () => {
            showModalAlert.value = true
            showModalAdd.value = false;
            emit('closeModal');
        };

        const init = () => {
            if (props.action === 'update' && props.videoDate) {
                videoTutorial.value.id = props.videoDate.id
                videoTutorial.value.name = props.videoDate.name
                videoTutorial.value.description = props.videoDate.description
                videoTutorial.value.document = props.videoDate.document
            }
        };
        init();

        const validate = () => {
            let valid = true
            if (!videoTutorial.value.name || !videoTutorial.value.tutorialType) {
                hasErrorNombre.value = videoTutorial.value.name ? false : true
                hasErrorDescipcion.value = videoTutorial.value.tutorialType ? false : true
                valid = false
            }

            return valid
        }
        const saveVideo = async () => {
            const isValidated = validate()
            if (isValidated) {
                if (file.value) videoTutorial.value.document = file.value
                if (props.action === 'create') {
                    await tutorialService.createTutorial(communityId.value, videoTutorial.value).then((response) => {
                        if (response.code === 201) {
                            showModalAlert.value = true
                        }
                    });
                } else if (props.action === 'update') {
                    const id = videoTutorial.value.id
                    console.log(communityId.value);
                    tutorialService.updateTutorial(communityId.value, id, videoTutorial.value)
                        .then((response) => {
                            if (response.code === 200) {
                                tutorialService.updateDocumentTutorial(communityId.value, id, videoTutorial.value)
                                showModalAlert.value = true
                            }
                        });
                }
            }
        };

        const handleAccept = () => {
            emit('closeModal')
        };

        return {
            handleAccept,
            closeModal,
            showModalAdd,
            message,
            videoTutorial,
            saveVideo,
            hasError,
            showModalAlert,
            hasErrorNombre,
            file,
            hasErrorDescipcion,
            titleModal,
            authUser,
            authUserCommunity
        };
    },
    components: { Modal, TextField, ModalAlert, InputFile },
});

