import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col h-full"
}
const _hoisted_2 = { class: "flex pt-2 pb-2 pl-4 items-center gap-2" }
const _hoisted_3 = { class: "mt-2 items-center" }
const _hoisted_4 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "flex h-full w-full items-center justify-center" }
const _hoisted_11 = { class: "flex h-4/6 w-full max-w-[50rem] xl:max-w-[85rem] flex-wrap justify-center p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CardEmergency = _resolveComponent("CardEmergency")!
  const _component_CoOwnerShipLawModal = _resolveComponent("CoOwnerShipLawModal")!

  return (_ctx.authUserCommunity?.userRole === 2 || _ctx.authUserCommunity?.userRole ===3 || _ctx.authUser?.isSuperuser)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("section", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbRoutes, (route, index) => {
              return (_openBlock(), _createElementBlock("span", { key: index }, [
                (index !== _ctx.breadcrumbRoutes.length - 1)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: route.path
                    }, {
                      default: _withCtx(() => [
                        (index === _ctx.breadcrumbRoutes.length - 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(route.name), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(route.name), 1))
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      (index === _ctx.breadcrumbRoutes.length - 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(route.name), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(route.name), 1))
                    ])),
                (index !== _ctx.breadcrumbRoutes.length - 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, " - "))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("section", _hoisted_10, [
          _createElementVNode("section", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventTutorial, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id,
                class: "w-full md:w-1/2 lg:w-1/2 flex p-4"
              }, [
                _createVNode(_component_CardEmergency, {
                  size: 15,
                  styleCard: item.styleCard,
                  class: "cursor-pointer",
                  figure: item.figure,
                  title: item.title,
                  onClick: ($event: any) => (_ctx.nexPageTutorial(item.id))
                }, null, 8, ["styleCard", "figure", "title", "onClick"])
              ]))
            }), 128))
          ])
        ]),
        (_ctx.ShowFlatModal)
          ? (_openBlock(), _createBlock(_component_CoOwnerShipLawModal, {
              key: 0,
              visible: _ctx.ShowFlatModal,
              onCloseModal: _ctx.handleCloseModal
            }, null, 8, ["visible", "onCloseModal"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}