import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40 z-50 flex justify-center items-center mt-auto"
}
const _hoisted_2 = {
  key: 0,
  class: "w-96 max-w-full bg-white rounded-lg p-6"
}
const _hoisted_3 = { class: "flex justify-center" }
const _hoisted_4 = { class: "text-2xl font-bold mb-4 mt-5 text-gray-500" }
const _hoisted_5 = { class: "mb-4 font-bold mb-4 mt-5 text-gray-400" }
const _hoisted_6 = { class: "flex justify-center" }
const _hoisted_7 = {
  key: 1,
  class: "w-96 max-w-full bg-white rounded-lg p-6"
}
const _hoisted_8 = { class: "flex justify-center" }
const _hoisted_9 = { class: "text-2xl font-bold mb-4 mt-5 text-gray-500" }
const _hoisted_10 = { class: "mb-4 font-bold mb-4 mt-5 text-gray-400" }
const _hoisted_11 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.type == 'success')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_mdicon, {
                  name: "check-circle-outline",
                  class: "text-green-300",
                  size: "150"
                })
              ]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.message), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  class: "mr-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOk && _ctx.handleOk(...args)))
                }, "Ok")
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.type == 'cancel')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_mdicon, {
                  name: "close-circle-outline",
                  class: "text-red-400",
                  size: "150"
                })
              ]),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.title), 1),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.message), 1),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", {
                  class: "mr-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)))
                }, "Ok")
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}