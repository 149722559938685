import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "mt-2 items-center" }
const _hoisted_3 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "w-full flex justify-start" }
const _hoisted_10 = { class: "flex flex-row gap-1" }
const _hoisted_11 = { class: "max-w-full bg-white rounded-lg" }
const _hoisted_12 = { class: "flex justify-center" }
const _hoisted_13 = { class: "flex flex-wrap justify-center mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_CardEmergency = _resolveComponent("CardEmergency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbRoutes, (route, index) => {
        return (_openBlock(), _createElementBlock("span", { key: index }, [
          (index !== _ctx.breadcrumbRoutes.length - 1)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: route.path
              }, {
                default: _withCtx(() => [
                  (index === _ctx.breadcrumbRoutes.length - 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(route.name), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(route.name), 1))
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                (index === _ctx.breadcrumbRoutes.length - 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(route.name), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(route.name), 1))
              ])),
          (index !== _ctx.breadcrumbRoutes.length - 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, " - "))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("button", {
        class: "flex border-2 border-success text-success rounded-lg p-2 hover:bg-green-100",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
      }, [
        _createElementVNode("strong", _hoisted_10, [
          _createVNode(_component_mdicon, { name: "backup-restore" }),
          _createTextVNode(" Volver a Emergencias ")
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("section", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventDate, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: _normalizeClass({ 'w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2': index < 4, 'w-full': index >= 4 })
            }, [
              _createVNode(_component_CardEmergency, {
                size: 200,
                styleCard: item.styleCard,
                class: "m-2 sm:m-4 hover:scale-105 cursor-pointer",
                icon: item.icon,
                title: item.title,
                onClick: ($event: any) => (_ctx.activeTypeSimulacrum(item.id))
              }, null, 8, ["styleCard", "icon", "title", "onClick"])
            ], 2))
          }), 128))
        ])
      ])
    ])
  ]))
}