
import { defineComponent, ref, computed } from 'vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import TableComponent from '@/components/general/molecules/TableComponent.vue';
import { GasCylinder, Unit } from '@/services/communities/types';
import { useRoute, useRouter } from 'vue-router';
import Modal from '../general/molecules/Modal.vue';

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const route = useRoute();
        const router = useRouter();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const CommunityServices = new CommunityService();
        const charge = ref<number>(0);
        const quantity = ref<number>(0);
        const showModal = ref(props.visible)
        const GasCylinderData = ref<GasCylinder[]>([]);
        const units = ref<Unit[]>([]);
        const unitList = ref()


        const columns = ref([{ name: 'charge', label: 'Carga' }, { name: 'quantity', label: 'Cantidad' }, { name: 'address', label: 'Unidad' }, { name: 'floor', label: 'Piso' }]);
 
        async function getGasCylinders() {
            try {
                const response = await CommunityServices.getGasCylindersByBlock(communityId.value, blockId.value)
                if (!response.success) {
                    GasCylinderData.value = [];
                }
                else {
                    GasCylinderData.value = response.data;
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        async function getUnits() {
            try {
                const response = await CommunityServices.getUnitsByBlock(blockId.value, communityId.value);
                if (response.success) {
                    units.value = response.data;
                    unitList.value = units.value.map((unit) => ({ value: unit.id, label: unit.address }));
                }

            }
            catch (error) {
                console.log(error);
            }
        }
        getUnits();
        getGasCylinders();
        const formattedData = computed(() => {
            return GasCylinderData.value.map((item) => {
                return {
                    id: item.id,
                    charge: item.charge,
                    quantity: item.quantity,
                    address: units.value.find((unit) => unit.id === item.unitId)?.address,
                    floor: units.value.find((unit) => unit.id === item.unitId)?.floor
                }
            })
        })

        const buttons = ref([

        ])


        const closeModal = () => {
            emit('closeModal')
        }
        return {
            CommunityServices,
            showModal,
            columns,
            buttons,
            charge,
            quantity,
            units,
            unitList,
            closeModal,
            formattedData
        };
    },
    components: { TableComponent, Modal }
})
