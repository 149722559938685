
import { defineComponent, ref, onMounted } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import { useRoute, useRouter } from 'vue-router';
import { EmergencyTypeInfo } from "@/services/emergencyType/types";

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        typeDate: {
            type: String,
        },
        sendDataEmergency: {
            type: Array,
        },
    },
    setup(props, { emit }) {
        // interface EmergencyTypeInfos {
        //     id: number;
        //     institution: string;
        //     description: string;
        //     phone: string;
        // }
        const route = useRoute();
        const showModal = ref<boolean>(props.visible)
        const showEditor = ref<boolean>(false)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const serviceData = ref(props.sendDataEmergency as EmergencyTypeInfo[]);
        const titleModal = ref(serviceData.value && serviceData.value[0].institution?  serviceData.value[0].institution :' ');



        const closeModal = () => {
            emit('closeModal')
        }

        const createEmergency = (action: any, emergencyType: any, emergencyData: any = null) => {
            emit('actionEmergency', action, emergencyType, emergencyData)
        }

        const activatedEditor = () => {
            showEditor.value = !showEditor.value;
        }

        return {
            showModal, closeModal, serviceData, createEmergency, activatedEditor, showEditor, titleModal
        };
    },
    components: { Modal },
});
