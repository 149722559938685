
import { defineComponent, ref, onMounted  } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute } from 'vue-router';
import TextField from '@/components/general/atoms/TextField.vue';
import InputFile from '@/components/general/molecules/InputFile.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { useAuthStore } from '@/stores/auth'
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
import InsuranceModalPdf from "@/components/queries/molecules/InsuranceModalPdf.vue"
const loading = ref<boolean>(false)

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        insuranceData: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const communityService = new CommunityService();
        const showModal = ref<boolean>(props.visible)
        const file = ref()
        const showModalAdd = ref<boolean>(false)
        const action = ref()
        const fileUrl = ref()
        const blobFile = ref<any>()
        const showDocument = ref<boolean>(false)
        const title = ref<string>('')
        const message = ref<string>('')
        const isEditing = ref<boolean>(false)
        const showModalAlert = ref<boolean>(false)
        const name = ref<string>(props.insuranceData?.name)
        const address = ref<string>(props.insuranceData?.address)
        const phone = ref<string>(props.insuranceData?.phone)
        const policyNumber = ref<string>(props.insuranceData?.policyNumber)
        const expirationDate = ref<string>(props.insuranceData?.expirationDate)
        const indexAlert = ref<number>(-1)
        
        const closeModal = () => {
            emit('closeModal')
        }

        const viewDocument = () => {
            showDocument.value = !showDocument.value;
        }

        const init = async () => {
            if (props.insuranceData) {
                await communityService.getInsuranceFile(communityId.value, props.insuranceData.id)
                    .then((response) => {
                        const blob: Blob = new Blob([response], {
                            type: "application/pdf; charset=utf-8",
                        });
                        blobFile.value = URL.createObjectURL(blob);
                    });
            }
        }

        onMounted(init);

        init();
        const updateDocument = async () => {
            if (props.insuranceData && file.value) {
                await communityService.uploadInsuranceFile(communityId.value, props.insuranceData.id, file.value)
                showModalAdd.value = false
            }
        }

        const updateInsurance = async () => {
            const item = {
                name: name.value,
                address: address.value,
                phone: phone.value,
                policyNumber: policyNumber.value,
                expirationDate: expirationDate.value,
                communityId: communityId.value
            }
            await communityService.updateInsurance(communityId.value, props.insuranceData?.id, item)
            isEditing.value = false
            emit('change')
        }

        const deleteInsurance = async () => {
            await communityService.deleteInsurance(communityId.value, props.insuranceData?.id)
            isEditing.value = false
            showModal.value = false
            closeModal()
            emit('change')
        }

        const downloadDocument = async () => {
            if (props.insuranceData) {
                await communityService.downloadInsuranceFile(communityId.value, props.insuranceData.id)
            }
        }
        const change = () => {
            showModal.value = false
        }
        const add = () => {
            showModalAdd.value = true
            action.value = 'updateDocument'
        }
        const alert = (index: number) => {
            switch (index) {
                case 0:
                    deleteInsurance()
                    break
                case 1:
                    updateInsurance()
                    break
            }
            showModalAlert.value = false
        }
        const alertOn = (index: number) => {

            switch (index) {
                case 0:
                    title.value = 'Eliminar seguro'
                    message.value = '¿Está seguro que desea eliminar el seguro?'
                    break
                case 1:
                    title.value = 'Editar seguro'
                    message.value = '¿Está seguro que desea editar el seguro?'
                    break

            }

            indexAlert.value = index
            showModalAlert.value = true
        }


        return {
            showModal, change, authUser, authUserCommunity, action,
            indexAlert, showDocument, showModalAdd, file, fileUrl, title, message,
            isEditing, name, address, phone, policyNumber, showModalAlert, expirationDate,
            alert, alertOn, deleteInsurance, updateInsurance, closeModal, viewDocument,
            add, updateDocument, downloadDocument, blobFile, loading
        };
    },
    components: { Modal, InputFile, TextField, ModalAlert, InsuranceModalPdf },
});
