
import { defineComponent, ref } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import TextField from '@/components/general/atoms/TextField.vue';
import Selector from '@/components/general/atoms/Selector.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute } from 'vue-router';
import { Users } from '@/services/communities/types'
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';

export default defineComponent({
    emit: ["update:visible", "update", "closeModal", "create"],
    props: {
        action: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Users,
            required: false,
        }
    },
    setup(props, { emit }) {

        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const CommunityServices = new CommunityService();
        const reducedCapacityDetails = ref<string>(props.user?.user.reducedCapacityDetails || '')
        const showModalAdd = ref<boolean>(props.visible)
        const userInfo = ref<Record<string, any>[]>([]);
        const userId = ref<number>(props.user?.userId || 0)
        const hasErrorReducedCapacity = ref<boolean>(false)
        const hasErrorUser = ref<boolean>(false)
        const showAlertModal = ref<boolean>(false)

        async function init() {
            await CommunityServices.getResidentByBlock(communityId.value, blockId.value)
                .then((response) => {
                    userInfo.value = response.data.map((user: any) => ({
                        value: user.user.id,
                        label: `${user.user.firstName} ${user.user.lastName} (${user.user.dni ? user.user.dni : 'No registra DNI'})`
                    }));
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        init();


        const closeModal = () => {
            showModalAdd.value = false
            emit('closeModal')
        }

        function runValidations() {
            let valid = true;
            if (userId.value == 0) {
                valid = false
                hasErrorUser.value = true
            }

            if (reducedCapacityDetails.value.length === 0) {
                hasErrorReducedCapacity.value = true
                valid = false
            }
            return valid
        }

        function handleAccept() {
            // showAlertModal.value = false
            emit('create')
        }

        const saveUser = async () => {
            if (runValidations()) {
                const userCommunity = await CommunityServices.getComunityUser(communityId.value, userId.value)
                const userUpdate = {
                    userUpdate: {
                        hasReducedCapacity: true,
                        reducedCapacityDetails: reducedCapacityDetails.value,
                    },
                }
                await CommunityServices.updateUsersDiminishedCapacities(communityId.value, userCommunity.data.id, userUpdate)
                    .then((response) => {
                        if (response.code === 200) {
                            emit('create')
                        }
                    });
            }
        };

        function onUpdateModelEstablishment(value: number) {
            userId.value = value
        }

        return {
            closeModal,
            saveUser,
            runValidations,
            showModalAdd,
            userId,
            communityId,
            reducedCapacityDetails,
            onUpdateModelEstablishment,
            userInfo,
            hasErrorReducedCapacity,
            hasErrorUser,
            showAlertModal,
            handleAccept
        };
    },
    components: { Modal, TextField, Selector, ModalAlert },
});
