import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red-400 text-left"
}
const _hoisted_2 = { class: "flex justify-between mt-4" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    title: 'Formulario de Certificaciones',
    onOnClose: _ctx.closeModal,
    visible: _ctx.showModal,
    background: 'bg-white  md:1/2 md:h-90 xl:w-1/3 ',
    "onUpdate:visible": _cache[4] || (_cache[4] = (e) => _ctx.showModal = e)
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "px-4 py-2 mr-2 rounded-lg bg-gray-300 hover:bg-gray-400 focus:bg-gray-400",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, "Cancelar"),
        _createElementVNode("button", {
          class: _normalizeClass(["px-4 py-2 rounded-md bg-primary text-white hover:bg-green-600 focus:bg-green-600", { 'opacity-50 cursor-not-allowed disabled:pointer-events-none': !_ctx.isFieldsFilled }]),
          disabled: !_ctx.isFieldsFilled
        }, [
          _createElementVNode("span", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
          }, _toDisplayString(_ctx.action === 'update' ? 'Editar' : 'Agregar'), 1)
        ], 10, _hoisted_3)
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.hasError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, "*Verifica que todos los campos esten rellenados "))
        : _createCommentVNode("", true),
      _createVNode(_component_TextField, {
        label: "Nombre del Certificado",
        model: _ctx.certificationType,
        "onUpdate:model": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.certificationType) = $event)),
        type: "text",
        name: "descripcion",
        placeholder: 'ej: Certificacion ascensor',
        hasError: _ctx.hasErrorNombre,
        "error-message": _ctx.hasErrorNombre ? 'El nombre de la Certificacion está vacío' : ''
      }, null, 8, ["model", "hasError", "error-message"]),
      _createVNode(_component_TextField, {
        label: "Fecha de vencimiento",
        name: "fecha",
        type: "date",
        model: _ctx.year,
        "onUpdate:model": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.year) = $event)),
        placeholder: 'ej: 2024',
        class: "w-full",
        "has-error": _ctx.hasErrorYears,
        "error-message": _ctx.hasErrorYears ? 'El año está vacío' : ''
      }, null, 8, ["model", "has-error", "error-message"]),
      (_ctx.showModalAlert)
        ? (_openBlock(), _createBlock(_component_ModalAlert, {
            key: 1,
            visible: _ctx.showModalAlert,
            option: true,
            onAccept: _ctx.handleAccept,
            onCancelAlert: _ctx.handleAccept
          }, null, 8, ["visible", "onAccept", "onCancelAlert"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onOnClose", "visible"]))
}