import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "text-2xl" }
const _hoisted_3 = { class: "mb-4 text-green-600" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "mb-4 text-green-600"
}
const _hoisted_7 = { class: "flex justify-between mt-4" }
const _hoisted_8 = {
  key: 0,
  class: "px-4 py-2 rounded-lg bg-primary text-white hover:bg-green-600 focus:bg-green-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Modal, {
      title: `${_ctx.videoTutorial.name}`,
      onOnClose: _ctx.closeModal,
      visible: _ctx.showModalView,
      "onUpdate:visible": _cache[2] || (_cache[2] = (e) => _ctx.showModalView = e)
    }, {
      default: _withCtx(() => [
        _createElementVNode("section", _hoisted_1, [
          _createElementVNode("ol", _hoisted_2, [
            _createElementVNode("li", _hoisted_3, _toDisplayString(_ctx.videoTutorial.description), 1)
          ])
        ]),
        (_ctx.videoTutorial.document)
          ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
              _createElementVNode("video", {
                src: _ctx.blobFile,
                controls: ""
              }, null, 8, _hoisted_5)
            ]))
          : (_openBlock(), _createElementBlock("section", _hoisted_6, " No se registran Videos asociados "))
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            class: "px-4 py-2 mr-2 rounded-lg bg-gray-300 hover:bg-gray-400 focus:bg-gray-400",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, "Cancelar"),
          (_ctx.authUserCommunity?.userRole === 3 || _ctx.authUser?.isSuperuser)
            ? (_openBlock(), _createElementBlock("button", _hoisted_8, [
                _createElementVNode("span", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateVideo && _ctx.updateVideo(...args)))
                }, "Editar")
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["title", "onOnClose", "visible"])
  ]))
}