
import { defineComponent, ref } from 'vue';
import CardEmergency from '@/components/general/molecules/CardEmergency.vue';
import { useRouter, useRoute } from 'vue-router';
import FlatModal from '@/components/tutorial/organisms/FlatModal.vue';
import { TutorialVideo } from '@/services/tutorials/type'
import { TutorialService } from '@/services/tutorials/TutorialsServices';
import VideoModal from '@/components/tutorial/organisms/video/VideoModal.vue'
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';

export default defineComponent({
    setup() {
        const router = useRouter();
        const route = useRoute();
        const ShowFlatModal = ref<boolean>(false)
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0)
        const flatSelected = ref<object>();
        const ShowFlatRegisterModal = ref<boolean>(false);
        const action = ref<string>('');
        const flatDataSend = ref<TutorialVideo[]>([]);
        const tutorialService = new TutorialService();
        const flatData = ref<TutorialVideo[]>([]);
        const faltId = ref<number>(0);
        const title = ref<string>("")
        const message = ref<string>("")
        const success = ref<boolean>(false)
        const loading = ref<boolean>(true)
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries`, name: 'Consultas' },
            { path: ``, name: 'Reglamentos' },
        ]

        const init = async() => {
            const type = 'REGULATIONS'
            await tutorialService.getTutorialType(communityId.value, type, blockId.value)
                .then((response) => {
                    if (response.code === 200) {
                        flatData.value = response.data
                    }
                })
            loading.value = false
        }
        init()

        const back = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries` });
        }

        const handleCloseModal = () => {
            ShowFlatModal.value = false
        }

        const openShowFlat = (actionSelect: string, flatData: any) => {
            flatDataSend.value = flatData.value
            ShowFlatModal.value = false
            action.value = actionSelect
            ShowFlatRegisterModal.value = true
        }

        const closeModal = () => {
            ShowFlatRegisterModal.value = false
            ShowFlatModal.value = false
            init()
        }

        const showFlat = (flatData: any) => {
            flatDataSend.value = flatData
            ShowFlatModal.value = true
        }
        const deleteItem = (flatItemId: any) => {
            faltId.value = flatItemId
            title.value = "¿Desea Eliminar?"
            message.value = "¿Está seguro que desea eliminar la comunidad?"
            ShowFlatModal.value = false
            success.value = true
        }

        const handleAcceptSuccess = async () => {
            success.value = false;
            ShowFlatModal.value = false;
            await tutorialService.deleteTutorial(communityId.value, faltId.value)
            init()
        };

        const handleCancelSuccess = () => {
            success.value = false;
            ShowFlatModal.value = false;
        };

        return {
            back, flatData, flatSelected, ShowFlatModal, handleCloseModal, ShowFlatRegisterModal, openShowFlat,
            action, closeModal, showFlat, flatDataSend, deleteItem, title, message, success, handleAcceptSuccess,
            handleCancelSuccess, breadcrumbRoutes, loading
        }
    },
    components: { CardEmergency, FlatModal, VideoModal, ModalAlert }
})
