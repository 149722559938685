
import { defineComponent, ref } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import TextField from '@/components/general/atoms/TextField.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth'
export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        constractosData: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const CommunityServices = new CommunityService();
        const showModal = ref<boolean>(props.visible)
        const showEditModal = ref<boolean>(false)
        const name = ref<string>(props.constractosData?.name)
        const address = ref<string>(props.constractosData?.address)
        const phone = ref<string>(props.constractosData?.phone)
        const email = ref<string>(props.constractosData?.email)
        const emergencyPhone = ref<string>(props.constractosData?.emergencyPhone)
        const managerName = ref<string>(props.constractosData?.managerName)
        const managerPhone = ref<string>(props.constractosData?.managerPhone)
        const managerEmail = ref<string>(props.constractosData?.managerEmail)

        const closeModal = () => {
            emit('closeModal')
        }
        const editServices = () => {
            showEditModal.value = !showEditModal.value
        }
        async function saveEditItem() {
            const data = {
                communityId: communityId.value,
                name: name.value,
                serviceType: props.constractosData?.serviceType,
                contractorType: props.constractosData?.contractorType,
                address: address.value,
                countyId: props.constractosData?.countyId,
                phone: phone.value,
                email: email.value,
                emergencyPhone: emergencyPhone.value,
                managerName: managerName.value,
                managerPhone: managerPhone.value,
                managerEmail: managerEmail.value,
                id: props.constractosData?.id
            }
            await CommunityServices.updateService(communityId.value, props.constractosData?.id, data)
                .then((response) => {
                    if (response) {
                        emit('edit')
                    }

                })
            showEditModal.value = !showEditModal.value

        }
        return {
            showModal,
            showEditModal,
            name,
            address,
            phone,
            email,
            emergencyPhone,
            managerName,
            managerPhone,
            managerEmail,
            authUser,
            authUserCommunity,
            closeModal,
            editServices,
            saveEditItem
        };
    },
    components: { Modal, TextField },
});
