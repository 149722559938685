
import { defineComponent, ref } from 'vue';
import CardEmergency from '@/components/general/molecules/CardEmergency.vue';
import { useRouter, useRoute } from 'vue-router';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';


export default defineComponent({
    setup() {
        const router = useRouter();
        const route = useRoute();
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0)
        const showFalseAlarm = ref<boolean>(false)
        const eventDate = [
            { id: 1, figure: 'fire_emergency', title: 'INCENDIO', styleCard: 'bg-danger ' },
            { id: 3, figure: 'tsunami_emergency', title: 'TSUNAMI', styleCard: 'bg-info' },
            { id: 2, figure: 'earthquake_emergency', title: 'TERREMOTO', styleCard: 'bg-orangeE' },
            { id: 4, figure: 'exit_emergency', title: 'EVACUACIÓN', styleCard: 'bg-success' },
        ]
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: ``, name: 'Emergencias' },
        ]
        const activeTypeEmergency = (emergencyId: any) => {
            const blockId = typeof route.params.id === 'string' ? parseInt(route.params.id) : 0;
            const communityId = typeof route.params.community === 'string' ? parseInt(route.params.community) : 0;

            switch (emergencyId) {
                case 1:
                    router.push({ path: `/community/${communityId}/block/${blockId}/emergency/${emergencyId}/alert/${emergencyId}` });
                    break;
                case 2:
                    router.push({ path: `/community/${communityId}/block/${blockId}/emergency/${emergencyId}/alert/${emergencyId}` });
                    break;
                case 3:
                    router.push({ path: `/community/${communityId}/block/${blockId}/emergency/${emergencyId}/alert/${emergencyId}` });

                    break;
                case 4:
                    router.push({ path: `/community/${communityId}/block/${blockId}/emergency/${emergencyId}/alert/${emergencyId}` });
                    break;
                case 5:
                    router.push({ path: `/community/${communityId}/block/${blockId}/emergency/${emergencyId}/simulacrum` });
                    break
                case 6:
                    showFalseAlarm.value = true
                    break;
            }
        }

        async function handleAccept() {
            showFalseAlarm.value = false
        }


        return { handleAccept, showFalseAlarm, eventDate, activeTypeEmergency, breadcrumbRoutes }
    },
    components: { CardEmergency, ModalAlert }
})
