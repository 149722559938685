
import { defineComponent, ref } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue';
import { TutorialVideo } from '@/services/tutorials/type'
import { getEnvConfig } from "@/configs/UrlConfig";
import { TutorialService } from '@/services/tutorials/TutorialsServices';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        videoData: {
            type: Object,
            required: false,
        },
    },
    setup(props, { emit }) {
        const route = useRoute();
        const videoTutorial = ref<TutorialVideo>({
            id: 0,
            name: '',
            description: '',
            tutorialType: 'TRAINING_VIDEOS',
            document: null,
            blockId: null,
        });
        const communityId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const tutorialService = new TutorialService();
        const showModalView = ref<boolean>(props.visible);
        const blobFile = ref<any>()

        const closeModal = () => {
            showModalView.value = false;
            emit('closeModal');
        };

        const init = async () => {
            if (props.videoData) {
                videoTutorial.value.id = props.videoData.id
                videoTutorial.value.name = props.videoData.name
                videoTutorial.value.description = props.videoData.description
                videoTutorial.value.document = props.videoData.document
                if (videoTutorial.value.document) {
                    const namefile = 'video.mp4'
                    await tutorialService.getTutorialFile(communityId.value, videoTutorial.value.id, namefile)
                        .then((response) => {
                            const blob: Blob = new Blob([response], {
                                type: "application/mp4; charset=utf-8",
                            });
                            blobFile.value = URL.createObjectURL(blob);
                        });
                }

            }
        };
        init();

        const handleAccept = () => {
            emit('closeModal')
        };

        const updateVideo = () => {
            const update = 'update'
            emit('editVideo', update, videoTutorial)
        };

        return {
            handleAccept,
            closeModal,
            showModalView,
            videoTutorial,
            getEnvConfig,
            updateVideo,
            blobFile,
            authUser,
            authUserCommunity
        };
    },
    components: { Modal },
});

