import { Response } from "../types";
import { AuthenticatedService } from "../auth/AuthenticatedServices";
import { getResponse, keysToSnake, fetchGetFileBodyRequired, fetchDownloadFileBodyRequired } from "../utils";
import { getEnvConfig } from "@/configs/UrlConfig";
import { TutorialVideo, TutorialVideoCreate, TutorialVideoUploadVideo } from './type'

export class TutorialService extends AuthenticatedService {

    async getTutorialType(communityId:number, tutorialType: string, blockId: number): Promise<Response<TutorialVideo[]>> {
        const params = {
            tutorial_type: tutorialType,
            block_id: blockId
        }
        const url = `${getEnvConfig().apiURL}/tutorials/community/${communityId}/get-tutorials-by-type/`;
        return getResponse(url, this.token, "POST", params);
    }

    async createTutorial(communityId:number, tutorialDate: TutorialVideoCreate) {
        const formData = new FormData()
        formData.append('name', tutorialDate.name);
        formData.append('description', tutorialDate.description);
        formData.append('tutorial_type', tutorialDate.tutorialType);
        formData.append('document', tutorialDate.document ? tutorialDate.document : '');
        if (tutorialDate.blockId !== null) {
            formData.append('block_id', tutorialDate.blockId.toString());
        }

        const url = `${getEnvConfig().apiURL}/tutorials/community/${communityId}/create`;
        return getResponse(url, this.token, "POST", undefined, formData);
    }

    async updateTutorial(communityId:number, tutorialId: number, tutorialDate: TutorialVideoCreate) {
        const formData = new FormData()
        formData.append('document', tutorialDate.document ? tutorialDate.document : '')

        const params = {
            name: tutorialDate.name,
            description: tutorialDate.description,
            block_id: tutorialDate.blockId
        }
        const url = `${getEnvConfig().apiURL}/tutorials/community/${communityId}/tutorial/${tutorialId}/update`;
        return getResponse(url, this.token, "POST", params);
    }

    async updateDocumentTutorial(communityId:number, tutorialId: number, tutorialDate: TutorialVideoCreate) {
        const formData = new FormData()
        formData.append('file', tutorialDate.document ? tutorialDate.document : '')
        const url = `${getEnvConfig().apiURL}/tutorials/community/${communityId}/tutorial/${tutorialId}/upload`;
        return getResponse(url, this.token, "POST", undefined, formData);
    }


    async deleteTutorial(communityId:number, tutorialId: number) {
        const url = `${getEnvConfig().apiURL}/tutorials/community/${communityId}/tutorial/${tutorialId}/delete`;
        return getResponse(url, this.token, "DELETE");
    }

    async getTutorialFile(communityId:number, tutorialId: number, fileName?: string): Promise<Blob> {
        const finalFileName = fileName || 'planos.pdf';
        const url = `${getEnvConfig().apiURL}/tutorials/community/${communityId}/tutorial/${tutorialId}/download`;
        return fetchGetFileBodyRequired(url, this.token, "GET", finalFileName);
    }

}