
import { defineComponent, ref } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import TextField from '@/components/general/atoms/TextField.vue';
import { useRoute } from 'vue-router';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { EmergencyTypeInfo } from "@/services/emergencyType/types";

import { EmergencyTypeService } from '@/services/emergencyType/EmergencyTypeServices';
export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String,
            default: '',
        },
        dateItem: {
            type: Object,

        },
        typeEmergency: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const route = useRoute();
        const showModalAlert = ref<boolean>(false)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const showRegisterModal = ref<boolean>(props.visible)
        const typeModal = ref<string>(props.typeEmergency === 'Carabineros' ? 'Carabineros de Chile' : props.typeEmergency === 'Security' ? 'Seguridad Ciudadana'
            : props.typeEmergency === 'Investigation' ? 'Investigaciones' : props.typeEmergency === 'Bomberos' ? 'Bomberos' : 'Emergencia')
        const emergencyDate = ref<EmergencyTypeInfo>({
            id: 0,
            institution: props.typeEmergency,
            description: '',
            phone: '',
        })
        const emergencyTypeService = new EmergencyTypeService();
        const init = async () => {
            if (props.dateItem != undefined && props.action === 'update') {
                emergencyDate.value.id = props.dateItem.id
                emergencyDate.value.description = props.dateItem.description
                emergencyDate.value.phone = props.dateItem.phone
            }
        }

        init()

        const closeModal = () => {
            emit('closeModal')
        }


        async function saveItem() {
            if (props.action === 'create') {
                emergencyTypeService.CreeEmergencyTypeIntitution(communityId.value, emergencyDate.value)
                    .then((response) => {
                        if (response.code === 201) {
                            showModalAlert.value = true;
                        }
                    })
            } else if (props.action === 'update') {
                emergencyTypeService.UpdateEmergencyTypeIntitution(emergencyDate.value.id, emergencyDate.value)
                    .then((response) => {
                        if (response.code === 200) {
                            showModalAlert.value = true;
                        }
                    })
            }
        }

        const handleCancel = () => {
            showModalAlert.value = false;
            closeModal()
        };

        const handleAccept = () => {
            showModalAlert.value = false;
            closeModal()
        };


        return {
            showRegisterModal,
            emergencyDate,
            closeModal,
            saveItem,
            showModalAlert,
            handleCancel,
            handleAccept,
            typeModal
        };
    },
    components: { Modal, TextField, ModalAlert },
});
