
import { defineComponent, ref } from 'vue';
import Modal from '../../../components/general/molecules/Modal.vue'
import { EventService } from '@/services/events/eventServices';
import { EventInstruction } from '@/services/events/type';
import { useRoute } from 'vue-router';
import Accordion from '@/components/general/atoms/Accordion.vue';

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        eventData: {
            type: Object,
        },
    },

    setup(props, { emit }) {
        const eventService = new EventService();
        const showModal = ref<boolean>(props.visible)
        const eventType = ref<string>(props.eventData?.eventType)
        const instructionData = ref<EventInstruction[]>([]);

        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const init = async () => {
            const response = await eventService.getEventInstruction(communityId.value, eventType.value);
            console.log(response.data)
            instructionData.value = response.data
        }

        init()

        const closeModal = () => {
            emit('closeModal')
        }

        return {
            showModal, closeModal, instructionData
        };
    },
    components: { Modal, Accordion },
});
