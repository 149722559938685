
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ComponentCard from '@/components/general/molecules/ComponentCard.vue';
import StepperBar from '@/components/general/atoms/StepperBar.vue';
import InstructionPersonal from '@/instructionsPersonal.json';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { Users, BlockInfo, GasCylinder } from '@/services/communities/types'
import DiscapaciterListModal from '@/components/emergencies/DiscapaciterListModal.vue'
import GasListModal from '@/components/emergencies/GasListModal.vue'
export default defineComponent({
    props: {
        simulacrum: {
            type: Boolean,
            default: false,
        },
    },

    setup(props, { emit }) {
        console.log(props.simulacrum)
        const route = useRoute();
        const router = useRouter();
        const CommunityServices = new CommunityService();
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0)
        const emergencyId = ref<number>(typeof route.params.emergencyId === 'string' ? parseInt(route.params.emergencyId) : 0)
        const emergencyTypeId = ref<number>(typeof route.params.emergencyTypeId === 'string' ? parseInt(route.params.emergencyTypeId) : 0)
        const stepId = ref<number>(typeof route.params.stepId === 'string' ? parseInt(route.params.stepId) : 0)
        const instructionName = ref<string>(emergencyTypeId.value === 1 ? 'Incendio' : emergencyTypeId.value === 3 ? 'Tsunami'
            : emergencyTypeId.value === 2 ? 'Terremoto' : 'Evacuación')
        const emergencyTabName = ref<string>(emergencyId.value == 5 ? 'Simulacro' : '')
        const stepDate = ref()
        const steps = ref(InstructionPersonal.filter(step => step.emergency_type_id === emergencyTypeId.value));
        const isLastStep = ref<boolean>(stepId.value === steps.value.length);
        const showOpenFinally = ref<boolean>(false)
        const userDiscapacities = ref<Users[]>([]);
        const GasCylinderData = ref<GasCylinder[]>([]);
        const blockData = ref<BlockInfo>();
        const iconoType = ref<string>(emergencyTypeId.value === 1 ? 'emergency/fire_emergency' : emergencyTypeId.value === 3 ? 'emergency/tsunami_emergency'
            : emergencyTypeId.value === 2 ? 'emergency/earthquake_emergency' : 'emergency/exit_emergency')
        const discapacitiesTotal = ref<number>(0);
        const gasTotal = ref<number>(0);
        const showOpenDiscapcities = ref<boolean>(false)
        const showOpenGas = ref<boolean>(false)
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/emergency`, name: 'Emergencias' },
            { path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}`, name: instructionName.value },
            { path: '', name:'Paso a paso'}
        ]

        const obtainStep = () => {
            const foundStep = InstructionPersonal.find(i => i.emergency_type_id === emergencyTypeId.value && i.stepId === stepId.value);
            stepDate.value = foundStep || null;
        };

        obtainStep()

        const initializerInstruction = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/instruction` });
        }
        async function getUserDiscapacities() {
            await CommunityServices.getResidentReduceCapacity(communityId.value)
                .then((response) => {
                    userDiscapacities.value = response.data
                    discapacitiesTotal.value = userDiscapacities.value.length
                })
                .catch((error) => {
                    console.error(error)
                })
        }

        async function getBlock() {
            await CommunityServices.getBlockCommunity(communityId.value)
                .then((response) => {
                    blockData.value = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
        }
        async function getGas() {
            await CommunityServices.getGasCylindersByBlock(communityId.value, blockId.value)
                .then((response) => {
                    GasCylinderData.value = response.data
                    gasTotal.value = GasCylinderData.value.length

                })
                .catch((error) => {
                    console.error(error)
                })
        }

        getUserDiscapacities()
        getBlock()
        getGas()

        const cancelSimulacrum = () => {
            showOpenFinally.value = true
        }

        const handleAccept = () => {
            showOpenFinally.value = false
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency` });
        }

        const nextStep = async (newStep: number) => {
            stepId.value = newStep + 1;
            if (stepId.value > steps.value.length) {
                showOpenFinally.value = true
            } else {
                if (props.simulacrum) {
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/step/${stepId.value}/simulacrum` });
                }
                else {
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/step/${stepId.value}` });

                }
                obtainStep();
            }
        };

        const backStep = async (backStep: number) => {
            stepId.value = backStep - 1
            if (props.simulacrum) {
                router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/step/${stepId.value}/simulacrum` });

            }
            else {
                router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/step/${stepId.value}` });
            }
            obtainStep()
        };

        const openModalDetails = async (item: string) => {
            switch (item) {
                case 'gas':
                    showOpenGas.value = true
                    break;
                case 'reducerCapacity':
                    showOpenDiscapcities.value = true
                    break;
            }
        };
        const handleCloseModal = () => {

            showOpenDiscapcities.value = false
            showOpenGas.value = false
        }
        return {
            blockData, discapacitiesTotal, handleAccept, showOpenFinally, isLastStep, steps, emergencyId,
            initializerInstruction, stepId, instructionName, emergencyTabName, stepDate, cancelSimulacrum,
            nextStep, backStep, gasTotal, iconoType, openModalDetails, showOpenGas, showOpenDiscapcities,
            handleCloseModal, breadcrumbRoutes
        };
    },

    components: {
        ComponentCard, StepperBar, ModalAlert, DiscapaciterListModal, GasListModal
    },
});
