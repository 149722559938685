
import { defineComponent, ref } from 'vue';
import CardEmergency from '@/components/general/molecules/CardEmergency.vue';
import { useRouter, useRoute } from 'vue-router';
import CoOwnerShipLawModal from '@/components/tutorial/organisms/CoOwnerShipLawModal.vue'
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    setup() {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const router = useRouter();
        const route = useRoute();
        const ShowFlatModal = ref<boolean>(false)
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0)
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: ``, name: 'Tutoriales' },
        ]
        const eventTutorial = [
            { id: 1, figure: 'tutorials/movie', title: 'Videos', styleCard: 'bg-success bg-gradient-to-b from-cardMenu text-4xl' },
            { id: 4, figure: 'tutorials/note-text-outline', title: 'Ley de copropiedad inmobiliaria', styleCard: 'bg-success bg-gradient-to-b from-cardMenu text-4xl' },
        ]

        const nexPageTutorial = (eventTutorial: any) => {
            switch (eventTutorial) {
                case 1:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/tutorial/video` });
                    break;
                case 4:
                    ShowFlatModal.value = true
                    break;
            }
        }

        const handleCloseModal = () => {
            ShowFlatModal.value = false
        }

        return {
            nexPageTutorial,
            eventTutorial,
            ShowFlatModal,
            handleCloseModal,
            breadcrumbRoutes,
            authUser,
            authUserCommunity
        }
    },
    components: { CardEmergency, CoOwnerShipLawModal }
})
