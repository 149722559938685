
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        icon: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        rectangular: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Number,
            required: true,
            default: 7,
        },
    },
    setup(props) {
        const windowWidth = ref(window.innerWidth)

        const figureStyle = computed(() => {
            let size = props.size
            if (windowWidth.value > 1268) {
                size = size * 1.5
            }
            return `width: ${size}rem;`
        })


        return { ...props, figureStyle };

    },
});
