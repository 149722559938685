
import { defineComponent, ref, computed, PropType } from "vue";
import ComponentCard from "./ComponentCard.vue";
import AddButton from "@/components/general/atoms/AddButton.vue";
import Selector from "../atoms/Selector.vue";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<any>,
      required: true,
    },
    columns: {
      type: Array as PropType<any>,
      required: true,
    },
    buttons: {
      type: Array as PropType<any>,
      required: true,
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Tabla Por Defecto",
    },
    sectionAddButton: {
      type: String,
      default: "",
    },
    searchTableData: {
      type: Array as PropType<any>,
      required: false,
    },
    perPage: {
      type: Number,
      default: 50,
    },
    isSelectPage: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  setup(props, { emit }) {
    const searchText = ref<string>("");
    const currentPage = ref<number>(1);
    const matches = ref<boolean>(true);
    const perPageSelect = ref(props.perPage);
    const typeRange = ref<number>(0);
    const showFirstPages = computed(() => currentPage.value <= 10);
    const showLastPages = computed(() => currentPage.value > totalPages.value - 10);
    const selectedRow = ref(null);

    const selectRow = (item: any) => {
      selectedRow.value = item;
    };
    const viewPageSelect = ref([
      { value: 0, label: "Por Defecto" },
      { value: 1, label: "0-10" },
      { value: 2, label: "0-20" },
      { value: 3, label: "0-40" },
      { value: 4, label: "0-60" },
      { value: 5, label: "0-100" },
      { value: 6, label: "0-150" },
    ]);

    function onUpdateModel(value: number) {
      typeRange.value = value;
      switch (Number(typeRange.value)) {
        case 0:
          perPageSelect.value = props.perPage;
          break;
        case 1:
          perPageSelect.value = 10;
          break;
        case 2:
          perPageSelect.value = 20;
          break;
        case 3:
          perPageSelect.value = 40;
          break;
        case 4:
          perPageSelect.value = 60;
          break;
        case 5:
          perPageSelect.value = 100;
          break;
        case 6:
          perPageSelect.value = 150;
          break;
        default:
          break;
      }
      currentPage.value = 1;
    }

    const visiblePages = computed(() => {
      if (showFirstPages.value) {
        return Array.from(
          { length: Math.min(10, totalPages.value) },
          (_, index) => index + 1
        );
      } else if (showLastPages.value) {
        return Array.from(
          { length: Math.min(10, totalPages.value) },
          (_, index) => totalPages.value - 10 + index
        );
      } else {
        return Array.from({ length: 10 }, (_, index) => currentPage.value - 5 + index);
      }
    });
    const resetParams = () => {
      matches.value = true;
    };
    const filteredItems = computed(() => {
      const start = (currentPage.value - 1) * perPageSelect.value;
      const end = start + perPageSelect.value;
      let totalMatches = 0;
      if (searchText.value === "") {
        resetParams();
        return props.items.slice(start, end);
      } else {
        return props.items
          .filter((item: Record<string, string>) => {
            return Object.keys(item).some((key: string) => {
              if (props.searchTableData.includes(key)) {
                const value = item[key] ? item[key].toLowerCase() : "";
                const match = value.includes(searchText.value.toLowerCase());
                if (match) {
                  resetParams();
                  totalMatches++;
                  if (totalMatches <= perPageSelect.value) matches.value = false;
                }
                return value.includes(searchText.value.toLowerCase());
              }
              return false;
            });
          })
          .slice(start, end);
      }
    });
    const closeAction = () => {
      emit("closeAction");
    };
    const totalPages = computed(() =>
      Math.ceil(props.items.length / perPageSelect.value)
    );
    const goToPage = (page: number) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };
    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };
    const lastPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value = totalPages.value;
      }
    };
    const firstPage = () => {
      currentPage.value = 1;
    };
    return {
      selectedRow,
      selectRow,
      searchText,
      filteredItems,
      previousPage,
      nextPage,
      currentPage,
      totalPages,
      matches,
      viewPageSelect,
      onUpdateModel,
      typeRange,
      goToPage,
      lastPage,
      firstPage,
      visiblePages,
      closeAction,
    };
  },
  components: { ComponentCard, AddButton, Selector },
});
