import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red-400 text-left"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "flex justify-between mt-4" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_InputFile = _resolveComponent("InputFile")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    title: `Formulario `,
    onOnClose: _ctx.closeModal,
    visible: _ctx.showModalAdd,
    "onUpdate:visible": _cache[9] || (_cache[9] = (e) => _ctx.showModalAdd = e)
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "px-4 py-2 mr-2 rounded-lg bg-gray-300 hover:bg-gray-400 focus:bg-gray-400",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, "Cancelar"),
        _createElementVNode("button", {
          class: _normalizeClass(["px-4 py-2 rounded-lg bg-primary text-white hover:bg-green-600 focus:bg-green-600", { 'opacity-50 cursor-not-allowed disabled:pointer-events-none': !_ctx.isFieldsFilled }]),
          disabled: !_ctx.isFieldsFilled
        }, [
          _createElementVNode("span", {
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.saveVideo && _ctx.saveVideo(...args)))
          }, "Guardar")
        ], 10, _hoisted_4)
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.hasError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, "*Verifica que todos los campos esten rellenados "))
        : _createCommentVNode("", true),
      _createVNode(_component_TextField, {
        label: "Nombre",
        model: _ctx.name,
        "onUpdate:model": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
        type: "text",
        name: "nombre",
        placeholder: 'ej: Seguro de edificio'
      }, null, 8, ["model"]),
      _createVNode(_component_TextField, {
        label: "Tipo de seguro",
        model: _ctx.insuranaceType,
        "onUpdate:model": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.insuranaceType) = $event)),
        type: "text",
        name: "nombre",
        placeholder: 'ej: Seguro de edificio',
        hasError: _ctx.hasErrorNombre,
        "error-message": _ctx.hasErrorNombre ? 'El nombre está vacío' : ''
      }, null, 8, ["model", "hasError", "error-message"]),
      _createVNode(_component_TextField, {
        label: "Dirección",
        model: _ctx.address,
        "onUpdate:model": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.address) = $event)),
        type: "text",
        name: "description",
        placeholder: 'ej: Calle 123',
        hasError: _ctx.hasErrorDescipcion,
        "error-message": _ctx.hasErrorDescipcion ? 'Descripcion vacía' : ''
      }, null, 8, ["model", "hasError", "error-message"]),
      _createVNode(_component_TextField, {
        label: "Mesa Central",
        model: _ctx.phone,
        "onUpdate:model": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phone) = $event)),
        type: "text",
        name: "description"
      }, null, 8, ["model"]),
      _createVNode(_component_TextField, {
        label: "N° de poliza",
        model: _ctx.policyNumber,
        "onUpdate:model": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.policyNumber) = $event)),
        type: "text",
        name: "description"
      }, null, 8, ["model"]),
      _createVNode(_component_TextField, {
        label: "Fecha de Vencimiento",
        model: _ctx.expirationDate,
        "onUpdate:model": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.expirationDate) = $event)),
        type: "date",
        name: "description"
      }, null, 8, ["model"]),
      (_ctx.action === 'create')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_InputFile, {
              label: "imagen",
              value: _ctx.file,
              onUploadFile: _cache[6] || (_cache[6] = ($event: any) => (_ctx.file = $event))
            }, null, 8, ["value"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showModalAlert)
        ? (_openBlock(), _createBlock(_component_ModalAlert, {
            key: 2,
            visible: _ctx.showModalAlert,
            option: true,
            onAccept: _ctx.handleAccept,
            onCancelAlert: _ctx.handleAccept
          }, null, 8, ["visible", "onAccept", "onCancelAlert"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onOnClose", "visible"]))
}