
import { defineComponent, ref } from "vue";

export default defineComponent({
    props: {
        title: {
            type: String,
            required: false,
        },
        visible: {
            type: Boolean,
            default: false,
            required: false
        },
        background: {
            type: String,
            required: false,
            default:"bg-white"

        }
    },

    emits: ["update:visible", "onClose"],

    setup(props, { emit }) {
        const openingModal = ref(false)

        function clickOutside() {
            if (!openingModal.value) {
                close()
            }
        }

        function close() {
            emit('update:visible', false)
        }

        return {
            openingModal, clickOutside, close
        }
    },

    watch: {
        visible(value: boolean) {
            if (!value) {
                this.$emit("onClose");
            }
            else {
                this.openingModal = true
                setTimeout(() => {
                    this.openingModal = false
                }, 500);
            }
        }
    }
});
