
import { defineComponent } from 'vue';

interface Field {
  label: string;
  name: string;
}

export default defineComponent({
  props: {
    object: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array as () => Field[],
      required: true,
    },
  },
  setup(props) {
    const formatDate = (value: any) => {
      if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/)) {
        const date = new Date(value);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      }
      return value;
    };

    return { formatDate };
  },
});
