
import { defineComponent, ref, computed, watch } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import TextField from '@/components/general/atoms/TextField.vue';
import Alert from '@/components/general/atoms/Alert.vue';
import Selector from '@/components/general/atoms/Selector.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute } from 'vue-router';
import { Users, unitBase } from '@/services/communities/types'
import { validateEmailStructure, validateRut, verifyPhoneNumberFormat } from '@/services/auth/AuthUtils'
import inputChipAuxVue from '@/components/general/molecules/inputChipAux.vue';
export default defineComponent({
    emit: ["update:visible", "update", "closeModal", "create"],
    props: {
        action: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Users,
            required: false,
        }
    },
    setup(props, { emit }) {

        const establishments = [
            { value: 1, label: 'Oficina' },
            { value: 2, label: 'Residencia' },
            { value: 3, label: 'Local Comercial' },
        ];
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.communityId === 'string' ? parseInt(route.params.communityId) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const CommunityServices = new CommunityService();
        const showEmployeeRegister = ref<boolean>(false)
        const firsName = ref<string>(props.user?.user.firstName || '')
        const lastName = ref<string>(props.user?.user.lastName || '')
        const phone = ref<string>(props.user?.user.phone || '')
        const email = ref<string>(props.user?.user.email || '')
        const age = ref<number>(props.user?.user.age || 0)
        const rut = ref<string>(props.user?.user.dni || '')
        const establishmentType = ref<number>(props.user?.communityUserUnits[0]?.unit.establishmentType || 0)
        const position = ref<string>(props.user?.position || '')
        const isCommiteMember = ref<boolean>(props.user?.isCommitteeMember || false)
        const commitePosition = ref<string>(props.user?.committeePosition || '')
        const hasReducedCapacity = ref<boolean>(props.user?.user.hasReducedCapacity || false)
        const reducedCapacityDetails = ref<string>(props.user?.user.reducedCapacityDetails || '')
        const parkingSpace = ref<string>(props.user?.parkingSpace || '')
        const activeInput = ref<boolean>(true)
        const warehouseSpace = ref<string>(props.user?.warehouseSpace || '')
        const isFab = ref<boolean>(props.user?.isFab || false)
        const floor = ref<number>(props.user?.communityUserUnits[0]?.unit.floor || 0)
        const showModalEdit = ref<boolean>(false)
        const searchTableData = ref([firsName, lastName, phone, email, rut, parkingSpace, warehouseSpace]);
        const showButtons = computed(() => true)
        const numBlock = ref<string>('')
        const address = ref<string>(props.user?.communityUserUnits[0]?.unit.address || '');
        const showAlertModal = ref<boolean>(false)
        const selectedUnit = ref<string>('0');
        const unitSelector = ref<number>(0);
        const profession = ref<string>(props.user?.user.profession || '')
        const message = ref<string>('');
        const loading = ref<boolean>(false);
        const hasPhoneError = ref<boolean>(false);
        const hasEmailError = ref<boolean>(false);
        const hasRutError = ref<boolean>(false);
        const showAlert = ref<boolean>(false);
        const wareHouses = ref<string[]>([]);
        const parkings = ref<string[]>([]);
        const changed = ref<boolean>(false);
        const units = ref<unitBase[]>([]);
        const showAlertError = ref<boolean>(false);
        const showAlertMessage = ref<string>('')
        const alertType = ref<string>('')
        const dropWareHouse = (itemIndex: number) => {
            wareHouses.value.splice(itemIndex, 1);
        };
        watch(showAlertError, (newValue) => {
            if (newValue) {
                setTimeout(() => {
                    showAlert.value = false;
                }, 3000);
            }
        });

        const addWareHouse = () => {
            if (warehouseSpace.value) {
                wareHouses.value.push(warehouseSpace.value)
                warehouseSpace.value = ''
            }
            else {
                showAlert.value = true;
                showAlertMessage.value = 'Campos vacios';
                alertType.value = 'warning';
            }

        };
        const addParking = () => {
            if (parkingSpace.value) {
                parkings.value.push(parkingSpace.value);
                parkingSpace.value = '';
            }

        }
        const dropParking = (itemIndex: number) => {
            parkings.value.splice(itemIndex, 1);
        }
        const isFieldsFilled = computed(() => {
            return firsName.value && lastName.value && phone.value && email.value && units.value.length > 0
        });
        function onUpdateModelEstablishment(value: number) {
            establishmentType.value = value
        }
        const showModalAdd = ref<boolean>(props.visible)
        const closeModal = () => {

            showModalAdd.value = false
            emit('closeModal')
        }
        const addUnit = () => {
            if (establishmentType.value && floor.value && address.value) {
                units.value.push({
                    floor: floor.value,
                    address: address.value,
                    establishmentType: Number(establishmentType.value),
                    blockId: blockId.value
                })
                floor.value = 0
                address.value = ''
            }

            console.log(units.value);

        }
        const dropUnit = (itemIndex: number) => {
            units.value.splice(itemIndex, 1);
        }
        const saveUser = async () => {
            if (props.action === 'create') {
                console.log(props.action);
                const userCommunity = {
                    userInfo: {
                        firstName: firsName.value,
                        lastName: lastName.value,
                        profession: profession.value,
                        age: age.value,
                        phone: phone.value,
                        hasReducedCapacity: hasReducedCapacity.value,
                        reducedCapacityDetails: reducedCapacityDetails.value,
                        email: email.value,
                        dni: rut.value,
                    },
                    communityUserInfo: {
                        position: position.value,
                        userRole: 1,
                        isActive: true,
                        isCommitteeMember: isCommiteMember.value,
                        committeePosition: commitePosition.value,
                        isFab: isFab.value,
                        communityId: communityId.value,
                    },
                    unitsInfo: units.value,
                    warehouses: wareHouses.value,
                    parkingSpots: parkings.value
                }
                await CommunityServices.createResidentInCommunity(communityId.value,userCommunity);
                emit('create')
                console.log("agregado")
            } else if (props.action === 'update' && props.user) {
                console.log('actualizar');
                const userUpdate = {
                    userUpdate: {
                        firstName: firsName.value,
                        lastName: lastName.value,
                        profession: profession.value,
                        age: age.value,
                        phone: phone.value,
                        hasReducedCapacity: hasReducedCapacity.value,
                        reducedCapacityDetails: reducedCapacityDetails.value,
                        email: email.value,
                        dni: rut.value,
                    },
                    communityUserEdit: {
                        userId: props.user?.userId,
                        communityId: communityId.value,
                        userRole: 1,
                        position: position.value,
                        parkingSpace: parkingSpace.value,
                        warehouseSpace: warehouseSpace.value,
                        isCommitteeMember: isCommiteMember.value,
                        committeePosition: commitePosition.value,
                        isFab: isFab.value,
                    },
                }
                await CommunityServices.updateUsersCommunity(communityId.value, props.user?.communityUserUnits[0]?.communityUserId, userUpdate);
                emit('create')
            }
        };
        function runValidations() {
            let valid = true;
            if (!validateRut(rut.value)) {
                valid = false
                hasRutError.value = true
            }
            else {
                hasRutError.value = false
            }
            if (!validateEmailStructure(email.value)) {
                valid = false
                hasEmailError.value = true
            }
            else {
                hasEmailError.value = false
            }
            return valid
        }

        return {
            closeModal,
            onUpdateModelEstablishment,
            saveUser,
            runValidations,
            dropWareHouse,
            addWareHouse,
            addUnit,
            dropUnit,
            addParking,
            dropParking,
            parkings,
            showModalAdd,
            wareHouses,
            showEmployeeRegister,
            establishmentType,
            firsName,
            lastName,
            profession,
            age,
            communityId,
            phone,
            hasReducedCapacity,
            isFab,
            reducedCapacityDetails,
            email,
            rut,
            position,
            commitePosition,
            isCommiteMember,
            parkingSpace,
            activeInput,
            warehouseSpace,
            searchTableData,
            showButtons,
            numBlock,
            hasRutError,
            hasEmailError,
            isFieldsFilled,
            establishments,
            floor,
            address,
            showModalEdit,
            showAlertModal,
            hasPhoneError,
            showAlert,
            message,
            loading,
            selectedUnit,
            unitSelector,
            units,
            showAlertError,
            showAlertMessage,
            alertType
        };
    },
    components: { Modal, TextField, Alert, Selector, inputChipAuxVue },
    watch: {
        'rut': {
            handler: function (newvalue) {
                if (!validateRut(newvalue)) {
                    this.hasRutError = true
                }
                else {
                    this.hasRutError = false
                }
            }
        },
        'email': {
            handler: function (newvalue) {
                if (!validateEmailStructure(newvalue)) {
                    this.hasEmailError = true
                }
                else {
                    this.hasEmailError = false
                }
            }
        },
        'phone': {
            handler: function (newvalue) {
                if (!verifyPhoneNumberFormat(newvalue)) {
                    this.hasPhoneError = true
                }
                else {
                    this.hasPhoneError = false
                }
            }
        },
    }
});
