
import { defineComponent, ref, computed, watch } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import TextField from '@/components/general/atoms/TextField.vue';
import Alert from '@/components/general/atoms/Alert.vue';
import Selector from '@/components/general/atoms/Selector.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute } from 'vue-router';
import { Users, Unit } from '@/services/communities/types'
import { validateEmailStructure, validateRut, verifyPhoneNumberFormat } from '@/services/auth/AuthUtils'
import SearchSelector from '@/components/queries/atoms/SearchSelector.vue';
import { unitBase } from '@/services/communities/types';
export default defineComponent({
    emit: ["update:visible", "update", "closeModal", "create"],
    props: {
        action: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Users,
            required: false,
        }
    },
    setup(props, { emit }) {
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const CommunityServices = new CommunityService();
        const showEmployeeRegister = ref<boolean>(false)
        const firsName = ref<string>(props.user?.user.firstName || '')
        const lastName = ref<string>(props.user?.user.lastName || '')
        const phone = ref<string>(props.user?.user.phone || '')
        const email = ref<string>(props.user?.user.email || '')
        const activeInput = ref<boolean>(true)
        const showModalEdit = ref<boolean>(false)
        const userInfo = ref<Users[]>([])
        const searchTableData = ref([firsName, lastName, phone, email]);
        const showButtons = computed(() => true)
        const numBlock = ref<string>('')
        const showAlertModal = ref<boolean>(false)
        const selectedUnit = ref<string>('0');
        const unitSelector = ref<number>(0);
        const message = ref<string>('');
        const loading = ref<boolean>(false);
        const hasPhoneError = ref<boolean>(false);
        const hasPhoneErrorCoOwner = ref<boolean>(false)
        const hasEmailError = ref<boolean>(false);
        const hasRutError = ref<boolean>(false);
        const showAlert = ref<boolean>(false);
        const showAlertMessage = ref<string>('')
        const alertType = ref<string>('')
        const currentUser = ref<Users>()
        const isCoOwner = ref<boolean>(true)
        const coOwnerEmergencyName = ref<string>(props.user?.coOwnerEmergencyName || '')
        const coOwnerEmergencyPhone = ref<string>(props.user?.coOwnerEmergencyPhone || '')
        watch(showAlert, (newValue) => {
            if (newValue) {
                setTimeout(() => {
                    showAlert.value = false;
                }, 3000);
            }
        });

        const isFieldsFilled = computed(() => {
            return currentUser.value && coOwnerEmergencyName.value && coOwnerEmergencyPhone.value
        });

        const isFieldsFilledUpdate = computed(() => {
            return firsName.value && lastName.value && phone.value && email.value && coOwnerEmergencyName.value && coOwnerEmergencyPhone.value
        })

        const showModalAdd = ref<boolean>(props.visible)
        const closeModal = () => {
            showModalAdd.value = false
            emit('closeModal')
        }
        const saveUser = async () => {
            if (props.action === 'create' && currentUser.value) {
                const userUpdate = {
                    userUpdate: {
                        firstName: currentUser.value.user.firstName,
                        lastName: currentUser.value.user.lastName,
                        profession: currentUser.value.user.profession,
                        age: currentUser.value.user.age,
                        phone: currentUser.value.user.phone,
                        hasReducedCapacity: currentUser.value.user.hasReducedCapacity,
                        reducedCapacityDetails: currentUser.value.user.reducedCapacityDetails,
                        email: currentUser.value.user.email,
                        dni: props.user?.user.dni,
                    },
                    communityUserEdit: {
                        userId: currentUser.value.userId,
                        communityId: communityId.value,
                        userRole: 1,
                        position: currentUser.value.position,
                        parkingSpace: currentUser.value.parkingSpace,
                        warehouseSpace: currentUser.value.warehouseSpace,
                        isCommitteeMember: currentUser.value.isCommitteeMember,
                        committeePosition: currentUser.value.committeePosition,
                        isFab: currentUser.value.isFab,
                        isCoOwner: true,
                        coOwnerEmail: currentUser.value.user.email,
                        coOwnerEmergencyName: coOwnerEmergencyName.value,
                        coOwnerEmergencyPhone: coOwnerEmergencyPhone.value
                    },
                }
                await CommunityServices.updateUsersCommunity(communityId.value, currentUser.value.communityUserUnits[0]?.communityUserId, userUpdate);

                emit('create')
            } else if (props.action === 'update' && props.user) {
                const userUpdate = {
                    userUpdate: {
                        firstName: firsName.value,
                        lastName: lastName.value,
                        profession: props.user.user.profession,
                        age: props.user.user.age,
                        phone: phone.value,
                        hasReducedCapacity: props.user.user.hasReducedCapacity,
                        reducedCapacityDetails: props.user.user.reducedCapacityDetails,
                        email: email.value,
                        dni: props.user.user.dni,
                    },
                    communityUserEdit: {
                        userId: props.user?.userId,
                        communityId: communityId.value,
                        userRole: 1,
                        position: props.user.position,
                        parkingSpace: props.user.parkingSpace,
                        warehouseSpace: props.user.warehouseSpace,
                        isCommitteeMember: props.user.isCommitteeMember,
                        committeePosition: props.user.committeePosition,
                        isFab: props.user.isFab,
                        isCoOwner: true,
                        coOwnerEmergencyName: coOwnerEmergencyName.value,
                        coOwnerEmergencyPhone: coOwnerEmergencyPhone.value
                    },
                }
                await CommunityServices.updateUsersCommunity(communityId.value, props.user?.communityUserUnits[0]?.communityUserId, userUpdate);
                emit('create')
            }
            currentUser.value = {} as Users
        };
        function runValidations() {
            let valid = true;
            if (!validateEmailStructure(email.value)) {
                valid = false
                hasEmailError.value = true
            }
            else {
                hasEmailError.value = false
            }
            return valid
        }
        const getResidentByBlock = async () => {
            await CommunityServices.getResidentByBlock(communityId.value, blockId.value)
                .then((response) => {
                    userInfo.value = response.data
                })
        }
        getResidentByBlock()
        const formattedUserList = computed(() => {
            return userInfo.value
                .filter(item => (!item.isCoOwner))
                .map(item => {
                    return {
                        id: item.id,
                        name: item.user.firstName + ' ' + item.user.lastName
                    }
                })
        })

        const onSelect = (item: any) => {
            currentUser.value = userInfo.value.find(item.id)
            console.log("aaa")
        }
        const onSelectUser = (item: any) => {
            currentUser.value = userInfo.value.find((user) => user.id === item.id)

            console.log("aaa", currentUser.value)
        }
        return {
            closeModal,
            saveUser,
            runValidations,
            onSelect,
            onSelectUser,
            showModalAdd,
            showEmployeeRegister,
            firsName,
            lastName,
            communityId,
            phone,
            email,
            activeInput,
            searchTableData,
            showButtons,
            numBlock,
            hasRutError,
            hasEmailError,
            isFieldsFilled,
            showModalEdit,
            showAlertModal,
            hasPhoneError,
            showAlert,
            message,
            loading,
            selectedUnit,
            unitSelector,
            showAlertMessage,
            alertType,
            formattedUserList,
            userInfo,
            isCoOwner,
            coOwnerEmergencyName,
            coOwnerEmergencyPhone,
            isFieldsFilledUpdate,
            hasPhoneErrorCoOwner

        };
    },
    components: { Modal, TextField, Alert, SearchSelector },
    watch: {
        'rut': {
            handler: function (newvalue) {
                if (!validateRut(newvalue)) {
                    this.hasRutError = true
                }
                else {
                    this.hasRutError = false
                }
            }
        },
        'email': {
            handler: function (newvalue) {
                if (!validateEmailStructure(newvalue)) {
                    this.hasEmailError = true
                }
                else {
                    this.hasEmailError = false
                }
            }
        },
        'phone': {
            handler: function (newvalue) {
                if (!verifyPhoneNumberFormat(newvalue)) {
                    this.hasPhoneError = true
                }
                else {
                    this.hasPhoneError = false
                }
            }
        },
        'coOwnerEmergencyPhone': {
            handler: function (newvalue) {
                if (!verifyPhoneNumberFormat(newvalue)) {
                    this.hasPhoneErrorCoOwner = true
                }
                else {
                    this.hasPhoneErrorCoOwner = false
                }
            }
        }
    }
});
