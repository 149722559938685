
import { computed, defineComponent } from 'vue'

export default defineComponent({
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false
        },
        type: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: false
        },
        model: {
            type: [String, Number],
            required: false
        },
        errorMessage: {
            type: String,
            default: "",
        },
        hasError: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        iconName: {
            type: String,
            required: false
        },
        autocomplete: {
            type: String,
            required: false
        },
        isAcopled: {
            type: Boolean,
            required: false,
            default: false
        },
        compact: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        saveOnFocus: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    emits: ["update:model", "dateSuccess"],

    setup(props, { emit }) {
        const errorMessageStyle = computed(() => props.hasError ? "" : "visibility: hidden")
        const saveInput = () => {
            emit('dateSuccess')
        }
        function updateModel(event: Event) {
            if (event.target === null) return;

            if (props.type == "number")
                emit('update:model', Number((event.target as HTMLInputElement).value))
            else
                emit('update:model', (event.target as HTMLInputElement).value)
        }

        return {
            errorMessageStyle, updateModel, saveInput
        }
    },
})
