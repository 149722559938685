
import { defineComponent, ref, computed } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import TextField from '@/components/general/atoms/TextField.vue';
// import Alert from '@/components/general/atoms/Alert.vue';
// import Selector from '@/components/general/atoms/Selector.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute } from 'vue-router';
import { userInfo } from '@/services/communities/types';
import { userBase } from '../../../services/communities/types';

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        action: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },

        commiteUserId: {
            type: Number,
            required: false,
            default: 0,
        }
    },
    setup(props, { emit }) {
        const establishments = [
            { value: 1, label: 'Oficina' },
            { value: 2, label: 'Residencia' },
            { value: 3, label: 'Local Comercial' },
        ];


        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const CommunityServices = new CommunityService();
        const committeUserDate = ref<userInfo>({
            userId: 0,
            position: '',
            parkingSpace: '',
            warehouseSpace: '',
            userRole: 0,
            isActive: false,
            isCommitteeMember: false,
            committeePosition: '',
            isFab: false,
            communityId: 0,
            id: 0,
            user: {
                firstName: '',
                lastName: '',
                profession: '',
                age: 0,
                email: '',
                phone: '',
                dni: '',
                hasReducedCapacity: false,
                reducedCapacityDetails: '',
            },
            units:[]
        });
        const message = ref<string>('');
        const loading = ref<boolean>(false);
        const hasPhoneError = ref<boolean>(false);
        const hasEmailError = ref<boolean>(false);
        const hasRutError = ref<boolean>(false);
        const showAlert = ref<boolean>(false);


        // const isFieldsFilled = computed(() => {
        //     return firstName.value && lastName.value && phone.value && email.value && rut.value && floor.value && address.value
        // });
        function onUpdateModelEstablishment(value: number) {
            // selectedEstablishment.value = value
        }
        const showModalAdd = ref<boolean>(props.visible)
        const closeModal = () => {
            showModalAdd.value = false
            emit('closeModal')
        }

        const init = () => {
            if (props.action === 'update') {
                CommunityServices.getCommitteUser(communityId.value, props.commiteUserId)
                    .then((response) => {
                        if (response.code === 200) {
                            committeUserDate.value = response.data
                        }
                    })
            }
        }

        init()

        const saveUser = async () => {
            console.log(committeUserDate.value);
            if (props.action === 'create') {
                console.log(props.action);
                // CommunityServices.createResidentInCommunity(committeUserDate.value)
                //     .then((response) => {
                //         if (response.code === 200) {
                //             committeUserDate.value = response.data;
                //         }
                //     });
            } else if (props.action === 'update') {
                console.log('actualizar');
                // Código para actualizar el usuario
            }
        };

        return {
            closeModal,
            onUpdateModelEstablishment,
            showModalAdd,
            hasEmailError,
            hasRutError,
            message,
            loading,
            establishments,
            hasPhoneError,
            showAlert,
            committeUserDate,
            saveUser

        };
    },
    // components: { Modal, TextField, Alert, Selector },
    components: { Modal, TextField},
});
