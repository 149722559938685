import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap w-full h-full p-4" }
const _hoisted_2 = { class: "flex w-full h-full border rounded-lg shadow-sm p-6" }
const _hoisted_3 = { class: "flex justify-start items-start font-semibold w-1/2" }
const _hoisted_4 = { class: "flex justify-start items-start w-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (row, index) => {
      return (_openBlock(), _createElementBlock("article", {
        key: index,
        class: "w-full md:w-1/2 flex justify-between"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("aside", _hoisted_3, _toDisplayString(row.label + ' :'), 1),
          _createElementVNode("aside", _hoisted_4, _toDisplayString(_ctx.formatDate(_ctx.object[row.name])), 1)
        ])
      ]))
    }), 128))
  ]))
}