
import { defineComponent, ref, computed, onMounted } from 'vue';
import TableComponent from '@/components/general/molecules/TableComponent.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute, useRouter } from 'vue-router';
import AddButton from '@/components/general/atoms/AddButton.vue';
import { Users } from '@/services/communities/types';
import ModalUserCommitte from '@/components/queries/molecules/ModalUserCommitte.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    setup() {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const route = useRoute();
        const router = useRouter();
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const CommunityServices = new CommunityService();
        const committeDate = ref<Users[]>([]);
        const showButtons = ref<boolean>(true);
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const searchTableData = ref(['firsName', 'lastName', 'position']);
        const showModal = ref<boolean>(false);
        const commiteUserId = ref()
        const action = ref<string>('')
        const currentItem = ref<Users>();
        const iDinamic = ref<number>(0)
        const showModalAlert = ref<boolean>(false)
        const title = ref<string>('')
        const message = ref<string>('')
        const loading = ref<boolean>(true)
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries`, name: 'Consultas' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries/committe`, name: 'Administración y comité' },
            { path: ``, name: 'Residentes del comité' },
        ]
        const columns = ref([
            { name: 'position', label: 'Cargo' },
            { name: 'firstName', label: 'Nombre' },
            { name: 'address', label: 'Unidad' },
            { name: 'phone', label: 'Movil' },
            { name: 'isFab', label: 'Fab' },
            { name: 'email', label: 'Email' }
        ]);
        const objetosModificados = ref<
            Array<{
                position: string;
                firstName: string;
                address: string;
                phone: string;
                email: string;
                isFab: string;
            }>
        >([]);

        const getCommunityResidents = async () => {
            try {
                const response = await CommunityServices.getCommitteUserAll(
                    communityId.value
                );
                if (response.code === 200) {
                    if (response.data.length > 0) {
                        committeDate.value = response.data
                        objetosModificados.value = committeDate.value.map((objeto) => ({
                            userId: objeto.userId ?? 'no registra',
                            position: objeto.position ?? 'no registra',
                            id: objeto.id ?? 'no registra',
                            firstName: objeto.user.firstName + ' ' + objeto.user.lastName ?? 'no registra',
                            phone: objeto.user.phone ?? 'no registra',
                            email: objeto.user.email ?? 'no registra',
                            address: objeto.communityUserUnits[0]?.unit?.address,
                            isFab: objeto.isFab ? 'SI' : 'NO',
                        }));
                    } else {
                        console.log('No hay personas en el comité');
                    }
                } else {
                    console.log('Error al obtener los miembros del comité');
                }
            } catch (error) {
                console.error(error);
            }
            loading.value = false
        };

        onMounted(getCommunityResidents);

        function addItem() {
            showModal.value = true
            action.value = 'create'
            console.log(showModal.value)
        }
        const updateTable = (item: any) => {
            currentItem.value = committeDate.value.find((x) => x.userId === item.userId);
            console.log("raro", item)
            commiteUserId.value = item.id
            showModal.value = true
            action.value = 'update'
        }
        const handleCancel = () => {
            showModalAlert.value = false;
        }

        const handleDelete = async () => {
            await CommunityServices.deletePersonalService(communityId.value, iDinamic.value)
            showModalAlert.value = false;
            getCommunityResidents()
        }
        const handleAccept = () => {
            showModalAlert.value = false;
            showModal.value = false
        }
        const deleteTable = async (registro: any) => {
            iDinamic.value = registro.id
            title.value = '¿Desea Eliminar?'
            message.value = 'No sera posible retroceder la Acción'
            showModalAlert.value = true
        }

        const buttons = ref<Array<{
            id: number;
            name: string;
            action: (item: any) => void;
            className: string;
            iconName: string;
        }>>([]);

        if (authUser?.isSuperuser || authUserCommunity?.userRole === 3) {
            buttons.value.push({
                id: 1,
                name: 'Editar',
                action: updateTable,
                className: 'flex items-center justify-center bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400',
                iconName: 'pencil'
            });
        } else {
            showButtons.value = false;
        }
        if (authUser?.isSuperuser) {
            buttons.value.push({ id: 2, name: 'Eliminar', action: deleteTable, className: 'bg-red-600 text-white hover:bg-red-500 focus:bg-red-500', iconName: 'delete' });
        }


        const cancel = () => {
            showModal.value = false
            console.log('cancel')
        }
        const back = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/committe` });
        }
        function create() {
            showModal.value = false
            getCommunityResidents()
            console.log("creadooo")
        }
        return {
            back,
            addItem,
            cancel,
            create,
            updateTable,
            deleteTable,
            handleAccept,
            handleCancel,
            handleDelete,
            columns,
            showButtons,
            searchTableData,
            buttons,
            committeDate,
            objetosModificados,
            currentItem,
            showModal,
            commiteUserId,
            action,
            showModalAlert,
            title,
            message,
            breadcrumbRoutes,
            loading,
            authUser,
            authUserCommunity,

        };
    },
    components: { AddButton, TableComponent, ModalUserCommitte, ModalAlert },
});
