
import { defineComponent, ref, computed, watch } from 'vue';
import Accordion from '@/components/general/atoms/Accordion.vue';
import { CommunityService } from "@/services/communities/CommunityServices";
import { Query } from "@/services/communities/types";
import { useRoute, useRouter } from 'vue-router';
import ComponentCard from '@/components/general/molecules/ComponentCard.vue';
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    setup() {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const router = useRouter();
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0); //TODO: usar id real
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0); //TODO: usar id real
        const loading = ref<boolean>(true);
        const search = ref<string>('');
        const communityService = new CommunityService();
        const formattedQueries = ref<{ title: string, content: string }[]>([]);
        const queries = ref<Query[]>([]);
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries`, name: 'Consultas' },
            { path: ``, name: 'Preguntas frecuentes' },
        ]
        async function getQueriescommunity() {
            queries.value = [];
            await communityService.getQueries()
                .then((response) => {
                    if (response.success) {
                        queries.value = response.data;
                        console.log(queries.value)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            await communityService.getQueriesByCommunity(communityId.value)
                .then((response) => {
                    if (response.success) {
                        queries.value = queries.value.concat(response.data);
                        console.log(queries.value)
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
            loading.value = false;
        }

        getQueriescommunity();
        const back = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries` });
        }
        watch(queries, () => {
            formattedQueries.value = queries.value.map((query) => {
                return {
                    title: query.question,
                    content: query.answer
                }
            })
        })
        const filteredItems = computed(() =>
            formattedQueries.value.filter(item =>
                item.title.toLowerCase().includes(search.value.toLowerCase())
            )
        );

        return { search, filteredItems, breadcrumbRoutes, loading, authUserCommunity, authUser, back };
    },
    components: { Accordion, ComponentCard }
});
