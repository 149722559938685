
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
const images = require.context("@/assets/", true, /\.png$/);

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: false,
    },
    figure: {
      type: String,
      required: true,
      default: "logoRRSS",
    },
    title: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
      default: 7,
    },
    iconSize: {
      type: [Number, String], // Updated the type to accept both Number and String
      default: "20", // Default size as a string, you can adjust this as needed
    },
    deleteItem: {
      type: Boolean,
      default: false,
    },
    textSize: {
      type: String,
      required: false,
      default: "md",
    },
    transparente: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const windowWidth = ref(window.innerWidth);

    const updateWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    const getImage = (imageName: string) => {
      return images(`./${imageName}.png`);
    };

    onMounted(() => {
      window.addEventListener("resize", updateWidth);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWidth);
    });

    const dynamicIconSize = computed(() => {
      let size = parseFloat(props.iconSize.toString()); // Convert iconSize to a number
      if (windowWidth.value > 1268) {
        size *= 1.5;
      }
      if (windowWidth.value < 800) {
        size /= 1.5;
      }
      return size;
    });
    const dynamicTextSize = computed(() => {
      let size = "xs";
      if (windowWidth.value > 1268) {
        size = "lg";
      } else if (windowWidth.value > 800) {
        size = "xs";
      }
      return size;
    });

    const figureStyle = computed(() => {
      let size = props.size;
      if (windowWidth.value > 800) {
        size *= 0.5;
      } else if (windowWidth.value > 300) {
        size *= 0.2;
      }
      console.log(windowWidth.value)

      return `width: ${size}rem;`;
    });

    return { figureStyle, getImage, dynamicIconSize, dynamicTextSize };
  },
});
