
import { defineComponent, ref } from 'vue';

interface AccordionItem {
    title: string;
    content: string | string[];
}
export default defineComponent({
    name: 'Accordion',
    props: {
        items: {
            type: Array as () => AccordionItem[],
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: 'w-full',
        },
        headerColor: {
            type: String,
            default: '',
        },
        bodyColor: {
            type: String,
            default: '',
        },
        isEvent: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const open = ref<number[]>(props.isEvent?props.items.map((_, index) => index):[]);
        function toggle(index: number) {
            if (props.multiple) {
                const i = open.value.indexOf(index);
                if (i === -1) {
                    open.value.push(index);
                } else {
                    open.value.splice(i, 1);
                }
            } else {
                if (open.value[0] === index) {
                    open.value = [];
                } else {
                    open.value = [index];
                }
            }
        }
        function isOpen(index: number) {
            return open.value.includes(index);
        }
        return {
            open,
            toggle,
            isOpen,
        };
    },
});
