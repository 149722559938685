import { Response } from "../types";
import { EmergencyTypeInfo } from "./types";
import { AuthenticatedService } from "../auth/AuthenticatedServices";
import { getResponse, keysToSnake } from "../utils";
import { getEnvConfig } from "@/configs/UrlConfig";
export class EmergencyTypeService extends AuthenticatedService {
    async getEmergencyTypeIntitution(communityId: number, blockId: number): Promise<Response<EmergencyTypeInfo[]>> {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/blocks/${blockId}/emergency-numbers`;
        return getResponse(url, this.token, "GET");
    }
    async CreeEmergencyTypeIntitution(blockId: number, emergencyData: EmergencyTypeInfo) {
        const url = `${getEnvConfig().apiURL}/community-extras/emergency-numbers/${blockId}/create`;
        return getResponse(url, this.token, "POST", emergencyData);
    }
    async UpdateEmergencyTypeIntitution(emergencyTypeId: number, emergencyData: EmergencyTypeInfo) {
        const url = `${getEnvConfig().apiURL}/community-extras/emergency-numbers/${emergencyTypeId}/update`;
        return getResponse(url, this.token, "POST", emergencyData);
    }

    async deactiveAlert(blockId: number, sendMessage: string) {
        const params = {
            send_message: sendMessage,
            is_active: false
        }
        const url = `${getEnvConfig().apiURL}/alerts/${blockId}/activation`;
        return getResponse(url, this.token, "POST", params);
    }
}