import { User } from "../auth/types";


class UserInfo implements User {
    constructor(
        public id: number | undefined = undefined,
        public firstName: string | undefined = undefined,
        public lastName: string | undefined = undefined,
        public phone: string | undefined = undefined,
        public email: string | undefined = undefined,
        public dni: string | undefined = undefined
    ) { }
}

export class UserCommunity {
    constructor(
        public user: UserInfo,
        public id: number,
        public userRole?: number,
        public warehouseSpace?: string,
        public parkingSpace?: string,
        public activeInput?: boolean
    ) { }
}

export class UserCommunityData {
    constructor(
        public user: UserCommunity,
        public address: string
    ) { }
}

export interface Pets{
    communityUserId: number
    type: number



}
export class PetsInfo implements Pets {
    constructor(
        public id: number | undefined = undefined,
        public communityUserId: number,
        public type: number,
        public unitId: number,
    ) { }
}