
import { defineComponent, ref, computed } from 'vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute, useRouter } from 'vue-router';
import TableComponent from '@/components/general/molecules/TableComponent.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import VehicleModal from '@/components/queries/molecules/VehicleModal.vue';
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    setup() {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const route = useRoute();
        const router = useRouter();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const CommunityServices = new CommunityService();
        const iDinamic = ref<number>(0)
        const title = ref<string>('')
        const loading = ref<boolean>(true)
        const message = ref<string>('')
        const action = ref<string>('')
        const vehicles = ref<object>([{ id: 0, patent: '', communityUserUnits: '', parkingSpace: '', communityUserId: 0 }])
        const vehicleSelected = ref<object>({})
        const showModalAlert = ref<boolean>(false)
        const showModal = ref<boolean>(false)
        const showButtons = computed(() => true)
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries`, name: 'Consultas' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries/census`, name: 'Empadronamiento y registro' },
            { path: '', name: 'Vehículos autorizados' }
        ]
        const searchTableData = ref(['patent', 'address', 'parkingSpace']);
        const columns = ref([{ name: 'patent', label: 'Patente' }, { name: 'communityUserUnits', label: 'Dirección' },
        { name: 'parkingSpace', label: 'Estacionamiento' }]);

        async function getVehicles() {
            const response = await CommunityServices.getVehicles(communityId.value);
            if (response.success) {
                vehicles.value = response.data.map((vehicle: any) => ({
                    id: vehicle.id,
                    patent: vehicle.patent,
                    communityUserUnits: vehicle.communityUserUnits[0]?.address || '',
                    parkingSpace: vehicle.communityUserParkingSpots[0]?.description || '',
                    communityUserId: vehicle.communityUserId
                }));
            }
            loading.value = false
        }

        getVehicles();
        const updateTable = (item: any) => {
            vehicleSelected.value = item
            showModal.value = true
            action.value = 'update'
        }

        const deleteTable = async (registro: any) => {
            iDinamic.value = registro.id
            title.value = '¿Desea Eliminar?'
            message.value = 'No sera posible retroceder la Acción'
            showModalAlert.value = true
        }

        const buttons = ref([
            { id: 1, name: 'Editar', action: updateTable, className: 'flex items-center justify-center bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400', iconName: 'pencil' },
        ]);

        if (authUser?.isSuperuser) {
            buttons.value.push({ id: 2, name: 'Eliminar', action: deleteTable, className: 'bg-red-600 text-white hover:bg-red-500 focus:bg-red-500', iconName: 'delete' });
        }


        const back = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/census` });
        }

        const closeModal = () => {
            getVehicles()
            showModal.value = false
        }


        const handleCancel = () => {
            showModalAlert.value = false;
        };

        const handleDelete = async () => {
            await CommunityServices.deleteVehicles(communityId.value, iDinamic.value)
            showModalAlert.value = false;
            getVehicles()
        };
        const handleAccept = () => {
            showModalAlert.value = false;
            showModal.value = false
            getVehicles()
        };
        return {
            vehicles, columns, searchTableData, buttons, showButtons, back, vehicleSelected,
            closeModal, handleCancel, handleDelete, handleAccept, showModalAlert, title,
            message, showModal, action, breadcrumbRoutes, loading, authUser, authUserCommunity
        };
    },
    components: { TableComponent, ModalAlert, VehicleModal },
})
