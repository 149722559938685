
import TextField from '@/components/general/atoms/TextField.vue';
import Modal from '@/components/general/molecules/Modal.vue';
import { defineComponent, ref } from 'vue';


export default defineComponent({

    props: {
        visible: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    emits: ["update:visible", "onClose"],
    
    setup() {

        const hasError = ref(false)
        const errorMessage = ref("")
        const success = ref(false)

        async function sendEmail() {
            // Envia el correo de verificacion o verifica que el correo este registrado
            // Cambia el estado de success, hasError y errorMessage después de la consulta
            return 
            
        }

        return {
            hasError,
            errorMessage,
            success

        };
    },
    components: { Modal, TextField }
})
