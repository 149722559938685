import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    title: 'Lista de personas con capacidades reducidas',
    onOnClose: _ctx.closeModal,
    visible: _ctx.showModal,
    "onUpdate:visible": _cache[0] || (_cache[0] = (e) => _ctx.showModal = e)
  }, {
    default: _withCtx(() => [
      (_ctx.formattedData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_TableComponent, {
              title: 'Cilindros de gas',
              items: _ctx.formattedData,
              columns: _ctx.columns,
              buttons: _ctx.buttons
            }, null, 8, ["items", "columns", "buttons"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onOnClose", "visible"]))
}