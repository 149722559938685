
export class Event {
    constructor(
        public id: number,
        public name: string,
        public eventType: string,
        public totalInstructions: number,
        public icon: string,
        public title: string,
    ) { }
}

export interface EventContainer {
  event_id: number;
  community_id: number;
  id: number;
  event: Event;
}

export class instruction {
    constructor(
        public id: number,
        public index: string,
        public description: string,
        public event_id: number,
        public title: string,
        public content: string,

    ) { }
}

export class EventInstruction {
    constructor(
        public id: number,
        public name: string,
        public total_instructions: number,
        public event_type: string,
        public instructions: instruction
    ) { }
}