
import { defineComponent, ref } from 'vue';
import CardEmergency from '@/components/general/molecules/CardEmergency.vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    props: {
        community: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
    },
    setup() {
        const router = useRouter();
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const eventMenu = [
            { id: 1, figure: 'logoRRSS', title: 'Emergencias', styleCard: 'bg-success bg-gradient-to-b from-cardMenu text-4xl' },
            { id: 2, figure: 'menu/queries', title: 'Consultas', styleCard: 'bg-success bg-gradient-to-b from-cardMenu text-4xl' },
            { id: 3, figure: 'menu/events', title: 'Eventos', styleCard: 'bg-success bg-gradient-to-b from-cardMenu text-4xl' },
            { id: 4, figure: 'menu/tutoriales', title: 'Tutoriales', styleCard: 'bg-success bg-gradient-to-b from-cardMenu text-4xl' },
        ]
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);

        async function routerPath(index: number) {
            switch (index) {
                case 1:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency` });
                    break;
                case 2:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries` });
                    break;
                case 3:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/events` });
                    break;
                case 4:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/tutorial` });
                    break;
            }
        }

        const filteredEventMenu = ref<Array<any>>([]);
        if (blockId.value === 0) {
            filteredEventMenu.value = eventMenu.slice(2, 4);
        } else {
            filteredEventMenu.value = eventMenu;
        }

        return { filteredEventMenu, routerPath, blockId,authUserCommunity,authUser };
    },

    components: { CardEmergency },
});
