
  import { defineComponent, ref } from 'vue';
  import Modal from '../../../components/general/molecules/Modal.vue';
  
  export default defineComponent({
    components: {
      Modal
    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      flatData: {
        type: Object,
      },
    },
    setup(props, { emit }) {
      const showModal = ref(props.visible);
      const pdfPath = ref('/pdf/pdfprueba.pdf');
  
      const downloadDocument = () => {
        console.log('Descargar documento');
      };
  
      const closeModal = () => {
        emit('closeModal');
      };
  
      return {
        showModal,
        pdfPath,
        downloadDocument,
        closeModal
      };
    },
  });
  