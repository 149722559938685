
import { defineComponent, ref, toRef } from 'vue';
import UsersModal from '@/components/queries/molecules/UsersModal.vue';
import WorkerModal from '@/components/queries/molecules/WorkerModal.vue';
import CertificationModal from '@/components/queries/molecules/census/CertificationModal.vue';
import VehicleModal from '@/components/queries/molecules/VehicleModal.vue';

export default defineComponent({
    props: {
        section: {
            type: String,
            default: '',
        },
        action: {
            type: String,
            default: 'create',
        }
    },
    setup(props, { emit }) {
        const sectioModify = toRef(props, "section");
        const keyIndexInvestModal = ref(0);
        const showUserModal = ref<boolean>(false)
        const showEmployeeRegister = ref<boolean>(false)
        const showCertificationRegister = ref<boolean>(false)
        const showVehicleRegister = ref<boolean>(false)

        function reload() {
            keyIndexInvestModal.value++;
            emit('reloadForm')
        }

        function openAdd() {
            switch (sectioModify.value) {
                case "createUser":
                    showUserModal.value = true;
                    break;
                case "createUserworker":
                    showEmployeeRegister.value = true;
                    break;
                case "createCertification":
                    showCertificationRegister.value = true;
                    break;
                case "createVehicle":
                    showVehicleRegister.value = true;
                    break;
                // case "pet":
                //     showPetRegister.value = true;
                //     break;
                // case "gas":
                //     showGasRegister.value = true;
                //     break;
                // case "form-register":
                //     showHandleRegister.value = true;
                //     break;
            }
        }
        const closeAction = () => {
            showUserModal.value = false;
            showEmployeeRegister.value = false;
            showCertificationRegister.value = false;
            showVehicleRegister.value = false;
            emit('closeAction')
        }

        return {
            closeAction,
            openAdd,
            reload,
            keyIndexInvestModal,
            showUserModal,
            showEmployeeRegister,
            showCertificationRegister,
            showVehicleRegister,
        }
    },
    components: {
        UsersModal, WorkerModal, CertificationModal, VehicleModal

    }

})

