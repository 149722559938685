
import { defineComponent, ref } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import TextField from '@/components/general/atoms/TextField.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute } from 'vue-router';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { vehiclesBase } from '@/services/communities/types';
import { useAuthStore } from '../../../stores/auth'

import Selector from '@/components/general/atoms/Selector.vue';

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String,
            default: '',
        },
        vehicleSelected: {
            type: Object,
        },

    },
    setup(props, { emit }) {
        const route = useRoute();
        const authStore = useAuthStore();
        const showModalAlert = ref<boolean>(false)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const CommunityServices = new CommunityService();
        const showRegisterModal = ref<boolean>(props.visible)
        const showEditModal = ref<boolean>(false)
        const userId = ref<number>(props.vehicleSelected?.userId || 0)
        const hasErrorPatent = ref<boolean>(false)
        const hasErrorUser = ref<boolean>(false)
        const userInfo = ref<Record<string, any>[]>([]);
        const vehicles = ref<vehiclesBase>({
            id: 0,
            patent: '',
            communityUserId: 0,
        })

        const init = async () => {
            await CommunityServices.getResidentByBlock(communityId.value, blockId.value)
                .then((response) => {
                    userInfo.value = response.data.map((user: any) => ({
                        value: user.user.id,
                        label: `${user.user.firstName} ${user.user.lastName} (${user.user.dni ? user.user.dni : 'No registra DNI'})`
                    }));
                })
                .catch((error) => {
                    console.error(error);
                });
            if (props.action === 'update' && props.vehicleSelected) {
                vehicles.value.id = props.vehicleSelected.id
                vehicles.value.patent = props.vehicleSelected.patent
                vehicles.value.communityUserId = props.vehicleSelected.communityUserId
                const userData = await CommunityServices.getUser(communityId.value, props.vehicleSelected.communityUserId)
                userId.value = userData.data.user.id

            }
        };
        init();

        const closeModal = () => {
            emit('closeModal')
        }

        const editServices = () => {
            showEditModal.value = !showEditModal.value
        }

        function runValidations() {
            let valid = true;
            if (userId.value == 0) {
                valid = false
                hasErrorUser.value = true
            }

            if (vehicles.value.patent === '') {
                hasErrorPatent.value = true
                valid = false
            }
            return valid
        }

        async function saveItem() {
            if (runValidations()) {

                const userCommunity = await CommunityServices.getComunityUser(communityId.value, userId.value)
                if (props.action === 'create') {
                    const userCreateVehicle = {
                        patent: vehicles.value.patent,
                        communityUserId: userCommunity.data.id
                    }
                    await CommunityServices.createVehicle(communityId.value, userCreateVehicle)
                        .then((response) => {
                            if (response.code === 201) {
                                showModalAlert.value = true;
                            }
                        })
                } else {
                    const userCreateVehicle = {
                        vehicleId: vehicles.value.id,
                        patent: vehicles.value.patent,
                        communityUserId: userCommunity.data.id
                    }
                    await CommunityServices.updateVehicles(communityId.value, userCreateVehicle)
                        .then((response) => {
                            if (response.code === 200) {
                                showModalAlert.value = true;
                            }
                        })
                }
            }
        }

        const handleCancel = () => {
            showModalAlert.value = false;
            closeModal()
        };

        const handleAccept = () => {
            showModalAlert.value = false;
            closeModal()
        };

        function onUpdateModelEstablishment(value: number) {
            userId.value = value
        }

        return {
            showRegisterModal,
            showEditModal,
            closeModal,
            editServices,
            saveItem,
            showModalAlert,
            handleCancel,
            handleAccept,
            vehicles,
            userId,
            onUpdateModelEstablishment,
            hasErrorPatent,
            hasErrorUser,
            userInfo,
        };
    },
    components: { Modal, TextField, ModalAlert, Selector },
});
