import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full" }
const _hoisted_2 = { class: "flex pt-2 pb-2 pl-4 items-center gap-2" }
const _hoisted_3 = { class: "mt-2 items-center" }
const _hoisted_4 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "flex flex-row h-full flex-wrap justify-center items-center" }
const _hoisted_11 = { class: "flex justify-between w-full h-10 mx-8" }
const _hoisted_12 = { class: "flex pt-2 pl-4 items-center justify-center gap-2" }
const _hoisted_13 = { class: "flex flex-row gap-1 mx-4" }
const _hoisted_14 = { class: "flex w-full h-4/5" }
const _hoisted_15 = { class: "flex h-4/5 xl:h-4/6 w-full justify-center" }
const _hoisted_16 = { class: "flex h-full w-full max-w-[70rem] xl:max-w-[85rem] flex-wrap justify-center p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_CardEmergency = _resolveComponent("CardEmergency")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbRoutes, (route, index) => {
          return (_openBlock(), _createElementBlock("span", { key: index }, [
            (index !== _ctx.breadcrumbRoutes.length - 1)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: route.path
                }, {
                  default: _withCtx(() => [
                    (index === _ctx.breadcrumbRoutes.length - 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(route.name), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(route.name), 1))
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  (index === _ctx.breadcrumbRoutes.length - 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(route.name), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(route.name), 1))
                ])),
            (index !== _ctx.breadcrumbRoutes.length - 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, " - "))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("section", _hoisted_10, [
      _createElementVNode("header", _hoisted_11, [
        _createElementVNode("aside", _hoisted_12, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTypeEmergency(6))),
            class: "flex border-2 bg-gris text-black font-normal rounded-2xl p-1 hover:bg-green-100"
          }, [
            _createElementVNode("p", _hoisted_13, [
              _createVNode(_component_mdicon, { name: "alarm-light-off" }),
              _createTextVNode(" Falsa alarma ")
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("section", _hoisted_16, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventDate, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id,
                class: "w-full md:w-1/4 lg:w-1/4 flex p-4"
              }, [
                _createVNode(_component_CardEmergency, {
                  size: 15,
                  styleCard: item.styleCard,
                  figure: item.figure,
                  title: item.title,
                  onClick: ($event: any) => (_ctx.activeTypeEmergency(item.id))
                }, null, 8, ["styleCard", "figure", "title", "onClick"])
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createVNode(_component_ModalAlert, {
      title: 'Falsa alarma',
      message: 'Si activa esta opción los residentes serán informados de que la alerta actual no es legitima.',
      visible: _ctx.showFalseAlarm,
      onAccept: _ctx.handleAccept,
      onCancelAlert: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFalseAlarm = false)),
      textArea: true
    }, null, 8, ["message", "visible", "onAccept"])
  ]))
}