import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    title: "Documento de ley de copropiedad inmobiliaria",
    onOnClose: _ctx.closeModal,
    visible: _ctx.showModal,
    "onUpdate:visible": _cache[0] || (_cache[0] = (e) => _ctx.showModal = e)
  }, {
    default: _withCtx(() => [
      _createElementVNode("embed", {
        src: _ctx.pdfPath,
        type: "application/pdf",
        width: "100%",
        height: "600px"
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["onOnClose", "visible"]))
}