
import { defineComponent, ref } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import TextField from '@/components/general/atoms/TextField.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute } from 'vue-router';
import { RegisterCompensation } from '@/services/communities/types';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { BaseRegion, BaseProvinceRegion, BaseCountyProvince } from "@/services/authExtra/types";
import { AdressService } from '@/services/authExtra/addressServices';


export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String,
            default: '',
        },
        itemServiceId: {
            type: Number,
            default: 0,
        },
        typeModal: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const route = useRoute();
        const adressService = new AdressService()
        const showModalAlert = ref<boolean>(false)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const CommunityServices = new CommunityService();
        const showRegisterModal = ref<boolean>(props.visible)
        const showEditModal = ref<boolean>(false)
        const couties = ref<BaseCountyProvince[]>()
        const regions = ref<BaseRegion[]>()
        const provincies = ref<BaseProvinceRegion[]>()
        const selectedRegion = ref<number>(0)
        const selectedProvincie = ref<number>(0)
        const serviceDate = ref<RegisterCompensation>({
            id: 0,
            name: '',
            serviceType: props.typeModal,
            communityId: 0,
            address: '',
            countyId: 0,
            phone: '',
            emergencyPhone: '',
        })

        const init = async () => {
            const response = await adressService.getAllRegions();
            regions.value = response.data;
            if (props.action === 'update') {
                await CommunityServices.getOneService(communityId.value, props.itemServiceId)
                    .then((response) => {
                        if (response.code === 200) {
                            serviceDate.value = response.data
                        }
                    })
            }
        }

        init()

        const closeModal = () => {
            emit('closeModal')
        }

        const editServices = () => {
            showEditModal.value = !showEditModal.value
        }

        async function saveItem() {
            if (props.action === 'create') {
                CommunityServices.createService(communityId.value, serviceDate.value)
                    .then((response) => {
                        if (response.code === 201) {
                            showModalAlert.value = true;
                        }
                    })

            } else if (props.action === 'update') {
                CommunityServices.updateService(communityId.value, props.itemServiceId, serviceDate.value)
                    .then((response) => {
                        if (response.code === 200) {
                            showModalAlert.value = true;
                        }
                    })
            }
        }

        const handleCancel = () => {
            showModalAlert.value = false;
            closeModal()
        };

        const handleAccept = () => {
            showModalAlert.value = false;
            closeModal()
        };

        const getProvincesRegion = async () => {
            selectedProvincie.value = 0
            const response = await adressService.getProvinceRegion(selectedRegion.value);
            provincies.value = response.data;
        }

        const getCoutyProvince = async () => {
            const response = await adressService.getCoutyProvince(selectedProvincie.value);
            couties.value = response.data;
        }
        return {
            showRegisterModal,
            showEditModal,
            serviceDate,
            closeModal,
            editServices,
            saveItem,
            showModalAlert,
            handleCancel,
            handleAccept,
            getProvincesRegion,
            getCoutyProvince,
            regions,
            couties,
            provincies,
            selectedRegion,
            selectedProvincie
        };
    },
    components: { Modal, TextField, ModalAlert },
});
