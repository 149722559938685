import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full h-full items-center rounded-lg shadow-md border border-gray-300 md:p-1 gap-1 justify-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex w-3/4 h-full items-center" }
const _hoisted_4 = { class: "flex mt-2 md:text-sm text-md font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("figure", {
      class: "flex w-1/4 items-center mt-1 justify-center",
      style: _normalizeStyle(_ctx.figureStyle)
    }, [
      _createElementVNode("img", {
        src: require('@/assets/' + _ctx.icon),
        class: "object-contain w-2/3 mx-auto"
      }, null, 8, _hoisted_2)
    ], 4),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}