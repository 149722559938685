
import { defineComponent, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { CommunityService } from '@/services/communities/CommunityServices';
import TextField from '@/components/general/atoms/TextField.vue';
import Modal from '@/components/general/molecules/Modal.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import InputFile from '@/components/general/molecules/InputFile.vue';
export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        action: {
            type: String,
            required: true,
        },
        insurance: {
            type: Object,
            required: false
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const communityServices = new CommunityService();
        const name = ref<string>(props.insurance?.name || '');
        const insuranaceType = ref<string>(props.insurance?.insuranaceType || '');
        const address = ref<string>(props.insurance?.address || '');
        const phone = ref<string>(props.insurance?.phone || '');
        const policyNumber = ref<string>(props.insurance?.policyNumber || '');
        const expirationDate = ref<string>(props.insurance?.expirationDate || '');
        const file = ref<File>();
        const hasError = ref(false)
        const hasErrorNombre = ref<boolean>(false);
        const hasErrorDescipcion = ref<boolean>(false);
        const message = ref<string>('');
        const showModalAdd = ref<boolean>(props.visible);
        const showModalAlert = ref<boolean>(false)
        const isFieldsFilled = computed(() => {
            return !!name.value && !!insuranaceType.value && !!address.value && !!phone.value && !!policyNumber.value && !!expirationDate.value;
        });

        const closeModal = () => {
            showModalAlert.value = true
            showModalAdd.value = false;
            emit('closeModal');
        };

        const saveVideo = async () => {
            if (props.action === 'create') {
                const insurance = {
                    name: name.value,
                    insuranaceType: insuranaceType.value,
                    address: address.value,
                    phone: phone.value,
                    policyNumber: policyNumber.value,
                    expirationDate: expirationDate.value,
                    document: file.value
                }

                await communityServices.createInsurance(communityId.value, insurance)
                emit('change')
            } else if (props.action === 'update') {
                if (props.insurance) {
                    const insurance = {
                        name: name.value,
                        insuranaceType: insuranaceType.value,
                        address: address.value,
                        phone: phone.value,
                        policyNumber: policyNumber.value,
                        expirationDate: expirationDate.value,
                        communityId: communityId.value
                    }
                    await communityServices.updateInsurance(communityId.value, props.insurance.id, insurance)
                    emit('change')

                }

            }
            else if (props.action === 'updateDocument') {
                emit('change')
            }

        };

        const handleAccept = () => {
            emit('closeModal')
        };

        return {
            handleAccept,
            closeModal,
            saveVideo,
            showModalAdd,
            message,
            name,
            insuranaceType,
            address,
            phone,
            policyNumber,
            expirationDate,
            file,
            hasError,
            showModalAlert,
            hasErrorNombre,
            hasErrorDescipcion,
            isFieldsFilled
        };
    },
    components: { Modal, TextField, ModalAlert, InputFile },
});

