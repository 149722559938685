
import { defineComponent, ref } from 'vue';
import Modal from '../../../components/general/molecules/Modal.vue'
import { TutorialVideo } from '@/services/tutorials/type'
import { getEnvConfig } from "@/configs/UrlConfig";
import { TutorialService } from '@/services/tutorials/TutorialsServices';
import VuePdfApp from "vue3-pdf-app";
import { useRoute } from 'vue-router';
import "vue3-pdf-app/dist/icons/main.css";
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        flatData: {
            type: Object,
        },
        typeDate: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const route = useRoute();
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const tutorialService = new TutorialService();
        const loading = ref<boolean>(true)
        const showModal = ref(props.visible)
        const blobFile = ref<any>()
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const flatTutorial = ref<TutorialVideo>({
            id: 0,
            name: '',
            description: '',
            tutorialType: props.typeDate,
            document: null,
            blockId: null,
        });
        const titleModal = ref<string>(flatTutorial.value.tutorialType === 'DIGITAL_PLANS' ? 'Planos' : 'Reglamento')

        const init = async () => {
            if (props.flatData) {
                flatTutorial.value.id = props.flatData.id
                flatTutorial.value.name = props.flatData.name
                flatTutorial.value.description = props.flatData.description
                flatTutorial.value.blockId = props.flatData.blockId
                flatTutorial.value.document = props.flatData.document
                loading.value = true
                if (flatTutorial.value.document) {
                    await tutorialService.getTutorialFile(communityId.value, flatTutorial.value.id)
                        .then((response) => {
                            const blob: Blob = new Blob([response], {
                                type: "application/pdf; charset=utf-8",
                            });
                            blobFile.value = URL.createObjectURL(blob);
                            loading.value = false
                        });
                }
            }
        };
        init();


        const closeModal = () => {
            emit('closeModal')
        }

        const handDelete = () => {
            emit('deleteItem', flatTutorial.value.id)
        }

        const updateFlat = () => {
            const update = 'update'
            emit('editFlat', update, flatTutorial)
        };
        return { showModal, loading, closeModal, flatTutorial, getEnvConfig, blobFile, updateFlat, titleModal, handDelete, authUser, authUserCommunity };
    },

    components: { Modal, VuePdfApp },
});
