
import { defineComponent, ref, computed, onMounted } from 'vue';
import TableComponent from '@/components/general/molecules/TableComponent.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { userInfo } from '@/services/communities/types';
import { useRoute, useRouter } from 'vue-router';
import WorkerModal from '@/components/queries/molecules/WorkerModal.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    setup() {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const route = useRoute();
        const router = useRouter();
        const communityServices = new CommunityService();
        const committeDate = ref<userInfo>();
        const showButtons = computed(() => true);
        const loading = ref<boolean>(true);
        const communityId = ref<number>(
            typeof route.params.community === 'string'
                ? parseInt(route.params.community)
                : 0
        );
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const searchTableData = ref(['firsName', 'lastName', 'position']);

        const showModal = ref<boolean>(false);
        const commiteUser = ref<object>({})
        const action = ref<string>('')
        const showModalAlert = ref<boolean>(false)
        const title = ref<string>('')
        const message = ref<string>('')
        let iDinamic = ref<number>(0)
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries`, name: 'Consultas' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries/committe`, name: 'Administración y comité' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries/committe/personal-service`, name: "Personal de servicio" },
            { path: ``, name: 'Nomina de trabajadores' },
        ]
        const columns = ref([
            { name: 'firstName', label: 'Nombre' },
            { name: 'lastName', label: 'Apellido' },
            { name: 'position', label: 'Cargo' },
            { name: 'phone', label: 'Telefono' },
            { name: 'email', label: 'Correo' },
        ]);
        const objetosModificados = ref<
            Array<{
                firstName: string;
                lastName: string;
                phone: string;
                email: string;
                position: string;
                userRoleWorkers: number;
            }>
        >([]);

        const getCommunityResidents = async () => {
            try {
                const response = await communityServices.getPersonal(communityId.value);
                if (response.code === 200) {
                    committeDate.value = response.data
                    objetosModificados.value = committeDate.value.map((objeto: any) => (
                        {
                            id: objeto.user.id ?? 'no registra',
                            userCommunityID: objeto.id ?? 'no registra',
                            firstName: objeto.user.firstName ?? 'no registra',
                            lastName: objeto.user.lastName ?? 'no registra',
                            phone: objeto.user.phone ?? 'no registra',
                            email: objeto.user.email ?? 'no registra',
                            position: objeto.position ?? 'no registra',
                            userRoleWorkers: objeto.userRole ?? 0

                        }));
                }
            } catch (error) {
                console.error(error);
            }
            loading.value = false
        };

        onMounted(getCommunityResidents);

        const updateTable = (item: any) => {
            commiteUser.value = item
            showModal.value = true
            action.value = 'update'
        }

        const deleteTable = async (registro: any) => {
            iDinamic.value = registro.userCommunityID
            title.value = '¿Desea Eliminar?'
            message.value = 'No sera posible retroceder la Acción'
            showModalAlert.value = true
        }
        const buttons = ref([
            { id: 1, name: 'Editar', action: updateTable, className: 'flex items-center justify-center bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400', iconName: 'pencil' },
        ]);

        if (authUser?.isSuperuser) {
            buttons.value.push({ id: 2, name: 'Eliminar', action: deleteTable, className: 'bg-red-600 text-white hover:bg-red-500 focus:bg-red-500', iconName: 'delete' });
        }


        const closeModal = () => {
            getCommunityResidents()
            showModal.value = false
        }

        const back = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/committe/personal-service` });
        }

        const handleCancel = () => {
            showModalAlert.value = false;
        };

        const handleDelete = async () => {
            await communityServices.deletePersonalService(communityId.value, iDinamic.value)
            getCommunityResidents()
            showModalAlert.value = false;
        };
        const handleAccept = () => {
            showModalAlert.value = false;
            showModal.value = false
            getCommunityResidents()
        };

        return {
            back,
            columns,
            showButtons,
            searchTableData,
            buttons,
            committeDate,
            objetosModificados,
            loading,
            updateTable,
            deleteTable,
            showModal,
            commiteUser,
            closeModal,
            action,
            showModalAlert,
            handleCancel,
            handleDelete,
            handleAccept,
            title,
            message,
            breadcrumbRoutes,
            authUser,
            authUserCommunity

        };
    },
    components: { TableComponent, WorkerModal, ModalAlert }
});


