
import { ref, onMounted, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { AuthService } from '@/services/auth/AuthServices'
import { useAuthStore } from '@/stores/auth'
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import Selector from '@/components/general/atoms/Selector.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { NotificationBase } from "@/services/notifications/types";
import { NotificationServices } from "@/services/notifications/NotificationServices";

export default defineComponent({

    setup() {
        interface Community {
            value: any;
            label: any;
        }
        const communityService = new CommunityService();
        const notificationServices = new NotificationServices();
        const isOpen = ref<boolean>(true)
        const loading = ref<boolean>(false)
        const showNotifications = ref<boolean>(false)
        const showModalAlert = ref<boolean>(false)
        const authService = new AuthService();
        const router = useRouter();
        const route = useRoute();
        const hasError = ref<boolean>(false)
        const authStore = useAuthStore();
        const authSuperUser = authStore.user;
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const buildingBlocks = ref<Community[]>([]);
        const notifications = ref<NotificationBase[]>([]);
        const menuOpen = ref<boolean>(false)
        const checkNotification = ref<number[]>([]);

        onMounted(async () => {
            init()
        });
        const init = () => {
            checkNotification.value = []
            obtainBlock()
            obtainNotification()
        }

        function addOpenAlert() {
            showModalAlert.value = true
        }

        const handleCancel = () => {
            showModalAlert.value = false;

        };
        const handleAccept = () => {
            showModalAlert.value = false
            logout()
        };

        const toggleMenu = () => {
            menuOpen.value = !menuOpen.value;
        }

        async function logout() {
            loading.value = true;
            const authToken = authStore.token as string;
            try {
                const response = await authService.logout(authToken);
                if (response.code == 204) {
                    authStore.logout();
                    router.push('/');
                }
            } catch (error) {
                console.error(error);
                hasError.value = true;
            } finally {
                loading.value = false;
            }
        }

        const obtainNotification = async () => {
            const authToken = authStore.userSession;
            if (authToken) {
                await notificationServices.getNotificationUser(authToken?.userId)
                    .then((response) => {
                        notifications.value = response.data.filter(item => item.isActive === true);
                    })
            }
        };

        const obtainBlock = async () => {
            const authToken = authStore.userSession;
            buildingBlocks.value = [];
            if (authToken) {
                await communityService.getComunityUser(communityId.value, authToken?.userId)
                    .then((response) => {
                        if (response.code === 200) {
                            communityService.getCommunityBlocks(response.data.communityId, response.data.id)
                                .then((response) => {
                                    if (response.code === 200) {
                                        if (response.data.length > 0) {
                                            response.data.forEach((obj: { id: any; name: any }) => {
                                                const newBlock = {
                                                    value: obj.id,
                                                    label: obj.name
                                                };
                                                buildingBlocks.value.push(newBlock);
                                            });
                                        }
                                    }
                                })
                        }
                    })
            }
        };

        const saveBlock = async (value: any) => {
            blockId.value = parseInt(value)
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/home` });
            init()
        }


        const saveNotification = async (id: number) => {
            const index = checkNotification.value.indexOf(id); // Buscar el índice del ID en el arreglo
            if (index !== -1) {
                checkNotification.value.splice(index, 1); // Eliminar el ID si se encuentra en el arreglo
            } else {
                checkNotification.value.push(id); // Agregar el ID al arreglo si no se encuentra
            }
        };

        const sendCheckNotification = async () => {

            const authToken = authStore.userSession;
            if (authToken) {
                const receivers = {
                    receiver_ids: checkNotification.value,
                    is_active: false
                }
                await notificationServices.updateNotifications(authToken?.userId, receivers)
                    .then((response) => {
                        init()
                    })
            }
        }

        const hiddenNotification = async () => {
            showNotifications.value = !showNotifications.value
            checkNotification.value = []
        }

        return {
            isOpen,
            addOpenAlert,
            showModalAlert,
            handleAccept,
            handleCancel,
            authSuperUser,
            blockId,
            buildingBlocks,
            saveBlock,
            communityId,
            toggleMenu,
            menuOpen,
            showNotifications,
            notifications,
            saveNotification,
            sendCheckNotification,
            checkNotification,
            hiddenNotification
        }
    },
    components: { ModalAlert, Selector },


})
