
import { defineComponent, ref, watch } from 'vue';
import ModalAlertResolved from './ModalAlertResolved.vue';
import TextField from '@/components/general/atoms/TextField.vue';
import { EmergencyTypeService } from '@/services/emergencyType/EmergencyTypeServices';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    props: {
        title: { type: String, default: '' },
        message: { type: String, default: '' },
        visible: { type: Boolean, default: true },
        option: { type: Boolean },
        itsCancel: { type: Boolean, default: false },
        textArea: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
        const route = useRoute();
        const type = ref(props.itsCancel ? 'cancel' : 'success');
        const titleSuccess = ref(props.itsCancel ? 'Cancelado' : 'Éxito!');
        const messageSuccess = ref(props.itsCancel ? 'La operación fue Cancelada.' : 'La operación fue realizada con éxito.');
        const isVisible = ref(props.visible);
        const success = ref(props.option);
        const messageResident = ref<string>('')
        const hasErrorMessageResident = ref<boolean>(false)
        const emergencyTypeService = new EmergencyTypeService();
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);

        watch(() => props.visible, (newValue) => {
            hasErrorMessageResident.value = false
            messageResident.value = ''

            isVisible.value = newValue;
        });

        const handleSuccess = async () => {
            if (props.textArea) {
                if (runValidations()) {
                    await emergencyTypeService.deactiveAlert(blockId.value, messageResident.value)
                }
            }
            success.value = true;
            titleSuccess.value = 'Realizado'
            messageSuccess.value = 'Operacion realizada con exito'
            type.value = "success";
        };

        const handleCancel = () => {
            success.value = true;
            titleSuccess.value = 'Cancelado'
            messageSuccess.value = 'Operacion Cancelada con exito'
            type.value = "cancel";
        };

        const handleCloseSuccess = () => {
            success.value = false;
            isVisible.value = false;
            emit('cancelAlert')
        };

        const handleAcceptSuccess = () => {
            success.value = false;
            isVisible.value = false;
            emit('accept');
            emit('deleteItem')
        };

        function runValidations() {
            let valid = true;
            if (messageResident.value.length == 0) {
                valid = false
                hasErrorMessageResident.value = true
            }
            return valid
        }



        return {
            success,
            handleSuccess,
            handleCloseSuccess,
            handleCancel,
            handleAcceptSuccess,
            type,
            titleSuccess,
            messageSuccess,
            isVisible,
            messageResident,
            hasErrorMessageResident
        };
    },
    components: {
        ModalAlertResolved, TextField
    },
});
