import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col h-full"
}
const _hoisted_2 = { class: "flex pt-2 pb-2 pl-4 items-center justify-between gap-2" }
const _hoisted_3 = { class: "mt-2 items-center" }
const _hoisted_4 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "flex pt-2 pl-4 items-center justify-center gap-2" }
const _hoisted_11 = { class: "flex flex-row gap-1" }
const _hoisted_12 = { class: "flex flex-row gap-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_CardEmergency = _resolveComponent("CardEmergency")!
  const _component_VideoModal = _resolveComponent("VideoModal")!
  const _component_TutorialViewModal = _resolveComponent("TutorialViewModal")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!

  return (_ctx.authUserCommunity?.userRole === 2 || _ctx.authUserCommunity?.userRole ===3 || _ctx.authUser?.isSuperuser)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("section", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbRoutes, (route, index) => {
              return (_openBlock(), _createElementBlock("span", { key: index }, [
                (index !== _ctx.breadcrumbRoutes.length - 1)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: route.path
                    }, {
                      default: _withCtx(() => [
                        (index === _ctx.breadcrumbRoutes.length - 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(route.name), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(route.name), 1))
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      (index === _ctx.breadcrumbRoutes.length - 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(route.name), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(route.name), 1))
                    ])),
                (index !== _ctx.breadcrumbRoutes.length - 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, " - "))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          _createElementVNode("aside", _hoisted_10, [
            _createElementVNode("button", {
              class: "flex border-2 border-success text-success rounded-lg p-1 hover:bg-green-100",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
            }, [
              _createElementVNode("strong", _hoisted_11, [
                _createVNode(_component_mdicon, { name: "backup-restore" }),
                _createTextVNode(" Volver a tutoriales ")
              ])
            ]),
            _createElementVNode("button", {
              class: "flex bg-success text-white rounded-lg p-1.5 hover:bg-green-600",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openShowVideo('create', '')))
            }, [
              _createElementVNode("strong", _hoisted_12, [
                _createVNode(_component_mdicon, { name: "youtube" }),
                _createTextVNode(" Agregar Tutorial ")
              ])
            ])
          ])
        ]),
        _createElementVNode("section", {
          class: _normalizeClass(["flex-col md:flex-row w-full md:w-full p-4 h-full items-stretch flex flex-wrap justify-center", _ctx.videoTutorial.length <= 5 ? 'h-2/6' : 'h-full'])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoTutorial, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "w-full sm:w-[20%]flex"
            }, [
              _createVNode(_component_CardEmergency, {
                class: "cursor-pointer flex-grow sm:w-[20%]",
                figure: 'tutorials/movie',
                title: item.name,
                size: 15,
                deleteItem: true,
                itemId: item.id,
                onHandDelete: _ctx.deleteItem,
                onClick: ($event: any) => (_ctx.showVideoTutorial(item))
              }, null, 8, ["title", "itemId", "onHandDelete", "onClick"])
            ]))
          }), 128))
        ], 2),
        (_ctx.ShowVideoModal)
          ? (_openBlock(), _createBlock(_component_VideoModal, {
              key: 0,
              action: _ctx.action,
              tutorialType: 'TRAINING_VIDEOS',
              videoDate: _ctx.videoDataSend,
              visible: _ctx.ShowVideoModal,
              onCloseModal: _ctx.closeModal
            }, null, 8, ["action", "videoDate", "visible", "onCloseModal"]))
          : _createCommentVNode("", true),
        (_ctx.showViewTutorialModal)
          ? (_openBlock(), _createBlock(_component_TutorialViewModal, {
              key: 1,
              videoData: _ctx.videoDataSend,
              visible: _ctx.showViewTutorialModal,
              onCloseModal: _ctx.closeModal,
              onEditVideo: _ctx.openShowVideo
            }, null, 8, ["videoData", "visible", "onCloseModal", "onEditVideo"]))
          : _createCommentVNode("", true),
        (_ctx.success)
          ? (_openBlock(), _createBlock(_component_ModalAlert, {
              key: 2,
              title: _ctx.title,
              message: _ctx.message,
              visible: _ctx.success,
              option: false,
              onAccept: _ctx.handleAcceptSuccess,
              onCancelAlert: _ctx.handleCancelSuccess
            }, null, 8, ["title", "message", "visible", "onAccept", "onCancelAlert"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}