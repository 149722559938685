
import AddButton from '@/components/general/atoms/AddButton.vue';
import TableComponent from '@/components/general/molecules/TableComponent.vue';
import { defineComponent, ref, computed } from 'vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import TextField from '@/components/general/atoms/TextField.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { FireExtinguisher } from '@/services/communities/types';
import Modal from '@/components/general/molecules/Modal.vue';
import Selector from '@/components/general/atoms/Selector.vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    setup() {
        const FireExtinguisherType = [
            { value: 1, label: 'SOLIDO' },
            { value: 2, label: 'LIQUIDO' },
            { value: 3, label: 'ELECTRICO' },
            { value: 4, label: 'METAL' },
            { value: 5, label: 'GRASOS' }
        ]
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const route = useRoute();
        const router = useRouter();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const CommunityServices = new CommunityService();
        const quantity = ref<number>(0);
        const maintenance = ref<string>('');
        const showModalAlert = ref<boolean>(false);
        const showModal = ref<boolean>(false);
        const typeBuilding = ref<number>(0);
        const showModalAdd = ref<boolean>(false);
        const showAlert = ref<boolean>(false);
        const showModalEdit = ref<boolean>(false);
        const showEditAlert = ref<boolean>(false);
        const loading = ref<boolean>(true);
        const currentItem = ref<FireExtinguisher>({} as FireExtinguisher);
        const showAlertModal = ref<boolean>(false);
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries`, name: 'Consultas' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries/census`, name: 'Empadronamiento y registro' },
            { path: '', name: 'Extintores' }
        ]
        const isFieldsFilled = computed(() => {
            return !!quantity.value && !!maintenance.value && !!typeBuilding.value//vencimiento.value && descripcion.value && typeBuilding.value;
        });
        const FireExtinguisherData = ref<FireExtinguisher[]>([]);
        const columns = ref([{ name: 'quantity', label: 'Cantidad' }, { name: 'extinguisherLabel', label: 'Tipo' }, { name: 'maintenance', label: 'Mantención' }]);
        const searchTableData = ref(['extinguisherLabel', 'maintenance']);
        async function getFireExtinguishers() {
            try {
                const response = await CommunityServices.getFireExtinguishers(communityId.value);
                if (!response.success) {
                    FireExtinguisherData.value = [];
                }
                else {
                    FireExtinguisherData.value = response.data;
                    console.log(FireExtinguisherData.value);
                }
            }
            catch (error) {
                console.log(error);
            }
            loading.value = false
        }
        getFireExtinguishers();
        const formattedData = computed(() => {
            return FireExtinguisherData.value.map((item) => {
                return {
                    id: item.id,
                    extinguisherType: item.extinguisherType,
                    extinguisherLabel: FireExtinguisherType[item.extinguisherType - 1].label,
                    quantity: item.quantity,
                    maintenance: item.maintenance
                }
            })
        })
        async function addItem() {
            quantity.value = 0
            maintenance.value = ''
            typeBuilding.value = 0
            showModalAdd.value = true
            currentItem.value = {} as FireExtinguisher
            typeBuilding.value = 0

        }
        async function saveEditItem() {
            showModalEdit.value = false
            const extinguisher = {
                quantity: quantity.value,
                maintenance: maintenance.value,
                extinguisherType: Number(typeBuilding.value)
            }

            await CommunityServices.updateFireExtinguisher(communityId.value, blockId.value, currentItem.value.id, extinguisher)
            currentItem.value = {} as FireExtinguisher
            showEditAlert.value = true
            getFireExtinguishers();

        }
        const showButtons = computed(() => true)
        const updateTable = (index: FireExtinguisher) => {
            currentItem.value = index
            showModalEdit.value = true
            typeBuilding.value = index.extinguisherType
            quantity.value = index.quantity
            maintenance.value = index.maintenance
            showEditAlert.value = false
        }

        const deleteTable = (index: FireExtinguisher) => {
            currentItem.value = index
            showAlertModal.value = true
            console.log(index, 'eliminar')
        }
        function onUpdateModel(value: number) {
            typeBuilding.value = value;
            console.log(value)
        }
        const buttons = ref([
            { id: 1, name: 'Editar', action: updateTable, className: 'flex items-center justify-center bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400', iconName: 'pencil' },
        ]);

        if (authUser?.isSuperuser) {
          buttons.value.push({ id: 2, name: 'Eliminar', action: deleteTable, className: 'bg-red-600 text-white hover:bg-red-500 focus:bg-red-500', iconName: 'delete' });
        }

        async function saveExtinguisher() {
            const extinguisher = {
                quantity: quantity.value,
                maintenance: maintenance.value,
                extinguisherType: Number(typeBuilding.value),
                blockId: blockId.value
            }
            await CommunityServices.createFireExtinguisher(communityId.value, blockId.value, extinguisher);
            showEditAlert.value = true
            getFireExtinguishers()
            showModalAdd.value = false
        }

        async function handleAccept() {
            try {
                showAlertModal.value = false;
                await CommunityServices.deleteFireExtinguisher(communityId.value, blockId.value, currentItem.value.id);
                currentItem.value = {} as FireExtinguisher
            } catch (error) {
                console.error(error);
            }
            await getFireExtinguishers();
        }

        const back = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/census` });
        }

        return {
            showModal,
            FireExtinguisherData,
            columns,
            showButtons,
            buttons,
            formattedData,
            searchTableData,
            showModalAlert,
            showModalAdd,
            FireExtinguisherType,
            showAlert,
            showAlertModal,
            showModalEdit,
            showEditAlert,
            isFieldsFilled,
            typeBuilding,
            quantity,
            maintenance,
            breadcrumbRoutes,
            loading,
            authUser,
            authUserCommunity,
            getFireExtinguishers,
            onUpdateModel,
            addItem,
            saveExtinguisher,
            handleAccept,
            saveEditItem,
            back
        };

    },
    components: { TableComponent, Modal, ModalAlert, AddButton, Selector, TextField }
})
