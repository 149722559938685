
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import "./assets/tailwind.css"
import { AuthService } from "./services/auth/AuthServices";
import NavBar from "./components/layout/NavBar.vue";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const authService = new AuthService();
    const showTopBar = computed(() => router.currentRoute.value.name != 'login');
    const showNavbar = computed(() => router.currentRoute.value.name != 'login' && router.currentRoute.value.name != 'reset-password' && router.currentRoute.value.name != 'block-list');
    const blockId = ref<number>(0);
    const communityId = ref<number>(0);
    const isLoading = ref<boolean>(true); 
    checkSession()
    
    async function checkSession() {
      const hasValidSession = await authService.hasValidUserSession();
      blockId.value = typeof route.params.id === 'string' ? parseInt(route.params.id) : 0;
      communityId.value = typeof route.params.community === 'string' ? parseInt(route.params.community) : 0;
      if (hasValidSession && blockId.value != 0 && communityId.value != 0) {
        isLoading.value = false; 
        const currentRoute = router.currentRoute.value
        return router.push({ path: currentRoute.path });
      } else {
        isLoading.value = false; 
        return router.push({ name: 'login' });
      }
    }
    return {
      showTopBar,
      showNavbar,
      blockId,
      communityId,
      isLoading
    };
  },

  components: { NavBar }
})
