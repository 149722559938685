
import { defineComponent, ref } from 'vue';
import CardEmergency from '@/components/general/molecules/CardEmergency.vue';
import { communitySeviceConstractor } from "@/services/communities/types";
import ConstractorsModal from '@/components/queries/molecules/ConstractorsModal.vue'
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute, useRouter } from 'vue-router';
import { capitalizeFirstLetter } from '@/services/utils'

export default defineComponent({
    setup() {
        const route = useRoute();
        const router = useRouter();
        const communityService = new CommunityService();
        const serviceData = ref<communitySeviceConstractor[]>([]);
        const showContractorModal = ref<boolean>()
        const constractorSelect = ref<object>();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0)
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const showModalEdit = ref<boolean>(false);
        const loading = ref<boolean>(true);
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries`, name: 'Consultas' },
            { path: ``, name: 'Contratistas autorizados' },
        ]
        const init = async () => {
            const response = await communityService.getServicesCommunity(communityId.value);
            const filteredServices = response.data.filter(service => service.serviceType === 'CONTRACTOR');
            serviceData.value = filteredServices
            communitySeviceConstractor.assignEventTypeIcons(serviceData.value);
            communitySeviceConstractor.assignEventTypeName(serviceData.value);
            loading.value = false
        }

        const goEventModal = (item: any) => {
            constractorSelect.value = item
            showContractorModal.value = true
        }
        const back = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries` });
        }
        const handleCloseModal = () => {
            showContractorModal.value = false
        }
        function editServices() {
            init()
            console.log("edit")
        }
        function getConstractorTypeIcon(type: string): string {
            switch (type) {
                case "GAS":
                    return 'queries/calendar-text-outline';
                case "ELECTRICITY":
                    return "queries/calendar-text-outline";
                case "WATER":
                    return "queries/calendar-text-outline";
                case "ELEVATORS":
                    return "queries/calendar-text-outline";
                case "BOILER_ROOM":
                    return "queries/calendar-text-outline";
                case "GENERATORS":
                    return "queries/calendar-text-outline";
                case "HYDROPACK":
                    return "queries/calendar-text-outline";
                case "GATES":
                    return "queries/calendar-text-outline";
                case "GYM":
                    return "queries/calendar-text-outlineh";
                case "WEAK_CURRENTS":
                    return "queries/calendar-text-outline";
                case "PLUMBER":
                    return "queries/calendar-text-outline";
                case "ELECTRICIAN":
                    return "queries/calendar-text-outline";
                case "LOCKSMITH":
                    return "queries/calendar-text-outline";
                default:
                    return "hola";
            }
        }
        init()
        return { serviceData, loading, goEventModal, showContractorModal, constractorSelect, handleCloseModal, editServices, capitalizeFirstLetter, back, breadcrumbRoutes, showModalEdit, getConstractorTypeIcon }
    },
    components: { CardEmergency, ConstractorsModal }
})
