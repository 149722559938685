import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center mt-5" }
const _hoisted_2 = {
  key: 1,
  class: "text-lg font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.totalSteps, (step) => {
      return (_openBlock(), _createElementBlock("div", {
        key: step,
        class: _normalizeClass([
            'w-8 h-8 flex items-center justify-center  mr-3 rounded-full',
            step == $props.stepActual ? ['  text-white', $props.isSimulacrum ? 'bg-warning' : 'bg-success'] : step < $props.stepActual ? ['  text-white', $props.isSimulacrum ? 'bg-warning' : 'bg-success'] : 'bg-white',
        ])
      }, [
        (step < $props.stepActual)
          ? (_openBlock(), _createBlock(_component_mdicon, {
              key: 0,
              name: "check-bold",
              class: "text-white"
            }))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(step), 1))
      ], 2))
    }), 128))
  ]))
}