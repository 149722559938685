
import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CardEmergency from '@/components/general/molecules/CardEmergency.vue';
export default defineComponent({

    setup(props, { emit }) {
        const eventDate = [
            { id: 1, figure: 'fire_emergency', title: '', styleCard: 'bg-danger ' },
            { id: 3, figure: 'tsunami_emergency', title: '', styleCard: 'bg-info' },
            { id: 2, figure: 'earthquake_emergency', title: '', styleCard: 'bg-orangeE' },
            { id: 4, figure: 'exit_emergency', title: '', styleCard: 'bg-success' },
        ]

        const route = useRoute();
        const router = useRouter();
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0)
        const emergencyId = ref<number>(typeof route.params.emergencyId === 'string' ? parseInt(route.params.emergencyId) : 0)
        const emergencyTypeId = ref<number>(typeof route.params.emergencyTypeId === 'string' ? parseInt(route.params.emergencyTypeId) : 0)
        const instructionName = ref<string>(emergencyTypeId.value === 1 ? 'Incendio' : emergencyTypeId.value === 3 ? 'Tsunami'
            : emergencyTypeId.value === 2 ? 'Terremoto' : 'Evacuacion')
        const emergencyTabName = ref<string>(emergencyId.value == 5 ? 'Simulacro' : '')
        const selectEvent = eventDate.find(event => event.id === emergencyTypeId.value);
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/emergency`, name: 'Emergencias' },
            { path: '', name: instructionName.value }
        ]
        const back = () => {
            if (emergencyId.value === 5) {
                router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/simulacrum/` });
            } else {
                router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency` });
            }
        }
        const initializerInstruction = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/instruction` });
        }

        const initializerEmergency = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/success` });
        }
        const initializerSimulacrum = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/success/simulacrum` });
        }

        return { emergencyId, back, initializerInstruction, instructionName, initializerEmergency, emergencyTabName, initializerSimulacrum, selectEvent, breadcrumbRoutes };
    },

    components: { CardEmergency },
});
