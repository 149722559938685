
import { defineComponent, ref, onMounted } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue'
import { CommunityService } from '@/services/communities/CommunityServices';
import { AdressService } from '@/services/authExtra/addressServices';
import { useRoute, useRouter } from 'vue-router';
import { communitySeviceConstractor } from '@/services/communities/types';
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const route = useRoute();
        const showModal = ref<boolean>(props.visible)
        const communityServices = new CommunityService()
        const adressService = new AdressService()
        const countyData = ref<object>()
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const serviceData = ref<communitySeviceConstractor[]>([]);
        const init = async () => {
            const serviceType = 'COMPENSATION_FUND';
            const response = await communityServices.getServicesCommunityAllType(communityId.value, serviceType);
            serviceData.value = response.data;
            const responseCounty = await adressService.getCoutyProvinceId(serviceData.value[0].countyId)
            countyData.value = responseCounty.data
        }

        onMounted(init);

        const closeModal = () => {
            emit('closeModal')
        }

        const actionService = (action: any, itemId: any) => {
            const compensacion = 'COMPENSATION_FUND'
            emit('actionServices', action, itemId, compensacion)
        }

        return {
            showModal, closeModal, serviceData, actionService, countyData, authUser, authUserCommunity
        };
    },
    components: { Modal },
});
