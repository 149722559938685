
import { defineComponent, ref, computed } from 'vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { certifications } from '@/services/communities/types'
import TableComponent from '@/components/general/molecules/TableComponent.vue';
import { useRoute, useRouter } from 'vue-router';
import CertificationModal from '@/components/queries/molecules/census/CertificationModal.vue';
import ModalAlert from '@/components/general/molecules/ModalAlert.vue';
import AddButton from '@/components/general/atoms/AddButton.vue';
import { useAuthStore } from '@/stores/auth'


export default defineComponent({
    setup() {
        const authStore = useAuthStore();
        const authUser = authStore.user;
        const authUserCommunity = authStore.userCommunity;
        const route = useRoute();
        const router = useRouter();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const communityServices = new CommunityService();
        const certification = ref<certifications[]>([])
        const showModal = ref<boolean>(false)
        const showCertification = ref<boolean>(false)
        const action = ref<string>('')
        const showButtons = ref<boolean>(true)
        const showModalAlert = ref<boolean>(false)
        const title = ref<string>('')
        const message = ref<string>('')
        const loading = ref<boolean>(true)
        const currentItem = ref<certifications>()
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries`, name: 'Consultas' },
            { path: `/community/${communityId.value}/block/${blockId.value}/queries/census`, name: 'Empadronamiento y registro' },
            { path: '', name: 'Certificados' }
        ]
        const searchTableData = ref(['certificationType', 'date']);
        const columns = ref([{ name: 'certificationType', label: 'Nombre' }, { name: 'month', label: 'Mes de Vencimiento' }, { name: 'year', label: 'Año de Vencimiento' }]);
        const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        async function getCertifications() {
            await communityServices.getCertificationsByBlock(communityId.value, blockId.value)
                .then((response) => {
                    certification.value = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
            loading.value = false
        }
        getCertifications()
        const formattedData = computed(() => {
            return certification.value.map((item) => {
                const date = new Date(item.date);
                const month = months[date.getMonth()];
                const year = date.getFullYear();
                return {
                    id: item.id,
                    certificationType: item.certificationType,
                    month: month,
                    year: year
                }
            })
        })


        const deleteTable = async (item: any) => {
            currentItem.value = certification.value.find((x) => x.id === item.id)
            title.value = '¿Desea Eliminar?'
            message.value = 'No sera posible retroceder la Acción'
            showModalAlert.value = true
        }

        const updateTable = (item: any) => {
            currentItem.value = certification.value.find((x) => x.id === item.id)
            action.value = 'update'
            showCertification.value = true
        }

        const buttons = ref<Array<{
            id: number;
            name: string;
            action: (item: any) => void;
            className: string;
            iconName: string;
        }>>([]);

        if (authUser?.isSuperuser || authUserCommunity?.userRole === 3) {
            buttons.value.push({
                id: 1,
                name: 'Editar',
                action: updateTable,
                className: 'flex items-center justify-center bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400',
                iconName: 'pencil'
            });
        } else {
            showButtons.value = false;
        }
        if (authUser?.isSuperuser) {
            buttons.value.push({ id: 2, name: 'Eliminar', action: deleteTable, className: 'bg-red-600 text-white hover:bg-red-500 focus:bg-red-500', iconName: 'delete' });
        }


        const back = () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/census` });
        }

        const cancel = () => {
            showCertification.value = false
            // getCertifications()
        }

        function addItem() {
            action.value = 'create'
            showCertification.value = true
        }
        const handleCancel = () => {
            showModalAlert.value = false;
        };
        const change = () => {
            showCertification.value = false
            getCertifications()
        }
        const handleDelete = async () => {
            if (currentItem.value) {
                await communityServices.deleteCertification(communityId.value, blockId.value, currentItem.value.id)
                showModalAlert.value = false;
                getCertifications()
            }
        };

        const handleAccept = () => {
            showModalAlert.value = false;
            showModal.value = false
        };

        return {
            columns,
            showButtons,
            searchTableData,
            buttons,
            currentItem,
            showModal,
            action,
            showCertification,
            certification,
            showModalAlert,
            title,
            message,
            formattedData,
            breadcrumbRoutes,
            loading,
            authUser,
            authUserCommunity,
            change,
            cancel,
            back,
            handleCancel,
            handleDelete,
            handleAccept,
            addItem
        }
    },
    components: { TableComponent, CertificationModal, ModalAlert, AddButton }
})
