import { useAuthStore } from "@/stores/auth";
import { getEnvConfig } from "@/configs/UrlConfig";
import { AlertCreate } from "./types";
import { getResponse, keysToSnake } from "../utils";
import { AuthenticatedService } from "../auth/AuthenticatedServices";
export class AuthService  extends AuthenticatedService {

  async createAlert(communityId: number, alert:AlertCreate) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/alerts/create`;
    return getResponse(url, this.token, "POST", keysToSnake(alert));
  }
}
