import { useAuthStore } from "@/stores/auth";
import { getEnvConfig } from "@/configs/UrlConfig";
import { User, UserSession } from "./types";
import { Response } from "../types";
import { getResponse } from "../utils";
import { mdiEarthOff } from "@mdi/js";

export class AuthService {
    async login(email: string, password: string): Promise<Response<UserSession>>{
        const url = `${getEnvConfig().apiURL}/auth/login`

        const formData = new FormData() 
        formData.append('username', email)
        formData.append('password', password)

        return getResponse(url, undefined, 'POST', undefined, formData)
    }

    async getProfile(token: string): Promise<Response<User>> {
        const url = `${getEnvConfig().apiURL}/auth/me`

        return getResponse(url, token)
    }

    async logout(token: string): Promise<Response<any>>{
        const url = `${getEnvConfig().apiURL}/auth/logout`

        return getResponse(url, token, 'POST')
    }

    async register(userInfo: object): Promise<Response<User>>{
        const url = `${getEnvConfig().apiURL}/auth/register`
        return getResponse(url, undefined, 'POST', userInfo)
    }

    /**
     * Valida que la sesion del usuario es valida.
     * @returns Retorna True si la sesión es valida y False en caso contrario
     */
    async hasValidUserSession(){
        const authStore = useAuthStore()
        if(authStore.token){
            const response = await this.getProfile(authStore.token)
            if(response.success){
                authStore.setUser(response.data)
                return true
            }
            else{
                authStore.logout()
                return false
            }
        }
        return false
    }
}