
import { defineComponent, ref } from 'vue';
import Modal from '../../general/molecules/Modal.vue'
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        blobFile: {
            type: String,
        },
        title: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const loading = ref<boolean>(true)
        const showModal = ref(props.visible)
        const blob = ref<any>(props.blobFile)
        const closeModal = () => {
            emit('closeModal')

        }

        return { showModal, loading, closeModal, blob };
    },

    components: { Modal, VuePdfApp },
});
