import { Response } from "../types";
import { UserCommunity, Pets,PetsInfo  } from "./types";
import { AuthenticatedService } from "../auth/AuthenticatedServices";
import { getResponse, keysToSnake } from "../utils";
import { getEnvConfig } from "@/configs/UrlConfig";

export class UserService extends AuthenticatedService {

    async getCommunityResidents(
        communityId: number
    ): Promise<Response<UserCommunity[]>> {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/residents`;
        return getResponse(url, this.token, "GET", undefined, undefined, UserCommunity);
    }

    async leaveUserCommunity(communityId: number, userId: number) {
        const params = keysToSnake(
            {
                userId
            }
        )
        const url = `${getEnvConfig().apiURL
            }/community/${communityId}/remove_user_community`;
        return getResponse(url, this.token, "DELETE", params, undefined);
    }

    async changeUserDetails(
        id: number,
        communityId: number,
        userId: number,
        userRole: number,
        parkingSpace: string,
        warehouseSpace: string
    ) {

        const params = keysToSnake(
            {
                id, userId, userRole, parkingSpace, warehouseSpace
            }
        )
        const url = `${getEnvConfig().apiURL
            }/community/${communityId}/edit-user`;
        return getResponse(url, this.token, "POST", params, undefined);
    }
    async getPets(communityId: number) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/user-pets`;
        return getResponse(url, this.token, "GET");
    }
    async createPets(communityId: number, pets: Pets) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/add-pet`;
        return getResponse(url, this.token, "POST", keysToSnake(pets));
    }
    async deletePets(communityId: number, petId: number) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/pet/${petId}/delete`;
        return getResponse(url, this.token, "DELETE");
    }
    async updatePets(communityId: number, petId: number, pets: PetsInfo) {
        const url = `${getEnvConfig().apiURL}/community/${communityId}/pet/${petId}/update`;
        return getResponse(url, this.token, "POST", keysToSnake(pets));
    }
}