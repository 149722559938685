
import { defineComponent, ref, computed } from 'vue';
import Modal from '@/components/general/molecules/Modal.vue';
import TableComponent from '@/components/general/molecules/TableComponent.vue';
import { Users } from '@/services/communities/types';
import { CommunityService } from '@/services/communities/CommunityServices';
import { useRoute } from 'vue-router';

export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        item: {
            type: Users,
            required: true,
        },
    },
    setup(props, { emit }) {
        const route = useRoute();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const CommunityServices = new CommunityService();
        const showModal = ref<boolean>(props.visible || false)
        const loading = ref<boolean>(true)
        const searchTableData = ref(['firsName', 'name'])
        const showButtons = computed(() => false)
        const residents = ref<Users[]>([])
        const columns = ref([{ name: 'description', label: 'Descripción' }])
        const columnsUnits = ref([{ name: 'address', label: 'Dirección' }, { name: 'floor', label: 'Pisos' }, { name: 'establishmentType', label: 'Tipo de establecimiento' }])
        const columnsResidents = ref([{ name: 'firstName', label: 'Nombre' }, { name: 'lastName', label: 'Apellido' }, { name: 'email', label: 'Email' }, { name: 'phone', label: 'Teléfono' }])
        const establishments = [
            { value: 1, label: 'Oficina' },
            { value: 2, label: 'Residencia' },
            { value: 3, label: 'Local Comercial' },
        ];
        const formattedData = computed(() => {
            if (props.item.communityUserUnits === undefined) {
                return []
            }

            return props.item.communityUserUnits.map((item) => {
                return {
                    floor: item.unit.floor,
                    address: item.unit.address,
                    establishmentType: establishments[item.unit.establishmentType - 1].label,
                    blockId: item.unit.blockId,
                }
            })
        })
        const getResidentByCoOwner = async () => {
            await CommunityServices.getResidentByCoOwner(communityId.value, props.item.id)
                .then((res) => {
                    residents.value = res.data
                })
                .catch((err) => {
                    console.log(err)
                })
            loading.value = false
        };
        getResidentByCoOwner()
        const residentFormattedData = computed(() => {
            return residents.value.map((item) => {
                return {
                    firstName: item.user.firstName,
                    lastName: item.user.lastName,
                    email: item.user.email,
                    phone: item.user.phone,
                }
            })
        })
        const closeModal = () => {
            // showModalAlert.value = true
            showModal.value = false
            console.log(showModal.value)
            emit('closeModal')
        };

        return {
            closeModal,
            showModal,
            columns,
            showButtons,
            searchTableData,
            columnsUnits,
            formattedData,
            residentFormattedData,
            columnsResidents,
            loading

        };
    },
    components: { Modal, TableComponent, },
});

