import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "text-red-400 text-left"
}
const _hoisted_3 = { class: "flex justify-between mt-4" }
const _hoisted_4 = { class: "px-4 py-2 rounded-lg bg-primary text-white hover:bg-green-600 focus:bg-green-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_InputFile = _resolveComponent("InputFile")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.authUserCommunity?.userRole === 2 || _ctx.authUserCommunity?.userRole ===3 || _ctx.authUser?.isSuperuser)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Modal, {
          title: `Formulario de ${_ctx.titleModal}`,
          onOnClose: _ctx.closeModal,
          visible: _ctx.showModalAdd,
          "onUpdate:visible": _cache[5] || (_cache[5] = (e) => _ctx.showModalAdd = e)
        }, {
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "px-4 py-2 mr-2 rounded-lg bg-gray-300 hover:bg-gray-400 focus:bg-gray-400",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
              }, "Cancelar"),
              _createElementVNode("button", _hoisted_4, [
                _createElementVNode("span", {
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.saveVideo && _ctx.saveVideo(...args)))
                }, "Guardar")
              ])
            ])
          ]),
          default: _withCtx(() => [
            (_ctx.hasError)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, "*Verifica que todos los campos esten rellenados "))
              : _createCommentVNode("", true),
            _createVNode(_component_TextField, {
              label: "Nombre Descriptivo ",
              model: _ctx.videoTutorial.name,
              "onUpdate:model": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.videoTutorial.name) = $event)),
              type: "text",
              name: "nombre",
              placeholder: 'ej: uso Extintor, documento de X cosa, etc',
              hasError: _ctx.hasErrorNombre,
              "error-message": _ctx.hasErrorNombre ? 'El nombre está vacío' : ''
            }, null, 8, ["model", "hasError", "error-message"]),
            _createVNode(_component_TextField, {
              label: "Descripción",
              model: _ctx.videoTutorial.description,
              "onUpdate:model": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.videoTutorial.description) = $event)),
              type: "text",
              name: "description",
              placeholder: 'ej: este es un archivo para visualizar X archivo',
              hasError: _ctx.hasErrorDescipcion,
              "error-message": _ctx.hasErrorDescipcion ? 'Descripcion vacía' : ''
            }, null, 8, ["model", "hasError", "error-message"]),
            _createVNode(_component_InputFile, {
              label: "video",
              value: _ctx.file,
              onUploadFile: _cache[2] || (_cache[2] = ($event: any) => (_ctx.file = $event))
            }, null, 8, ["value"]),
            (_ctx.showModalAlert)
              ? (_openBlock(), _createBlock(_component_ModalAlert, {
                  key: 1,
                  visible: _ctx.showModalAlert,
                  option: true,
                  onAccept: _ctx.handleAccept,
                  onCancelAlert: _ctx.handleAccept
                }, null, 8, ["visible", "onAccept", "onCancelAlert"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["title", "onOnClose", "visible"])
      ]))
    : _createCommentVNode("", true)
}