import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center min-h-screen" }
const _hoisted_2 = {
  key: 0,
  class: "text-red-400 text-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ComponentCard = _resolveComponent("ComponentCard")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ComponentCard, {
      title: "Formulario de Registro",
      class: "sm:w-96 p-4 mb2"
    }, {
      header: _withCtx(() => []),
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createAccount && _ctx.createAccount(...args)), ["prevent"])),
          class: "flex flex-col gap-4"
        }, [
          (_ctx.emptyFields)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, "*Por favor, complete todos los campos. "))
            : _createCommentVNode("", true),
          _createVNode(_component_TextField, {
            label: "Nombre",
            name: "name",
            type: "string",
            model: _ctx.firstName,
            "onUpdate:model": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstName) = $event)),
            class: "w-full",
            "has-error": _ctx.firstName.trim().length === 0 && _ctx.hasError,
            "error-message": 'El nombre está vacío'
          }, null, 8, ["model", "has-error"]),
          _createVNode(_component_TextField, {
            label: "Apellidos",
            name: "lastName",
            type: "string",
            model: _ctx.lastName,
            "onUpdate:model": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastName) = $event)),
            class: "w-full",
            "has-error": _ctx.lastName.trim().length === 0 && _ctx.hasError,
            "error-message": 'El apellido está vacío'
          }, null, 8, ["model", "has-error"]),
          _createVNode(_component_TextField, {
            label: "RUT",
            name: "rut",
            type: "string",
            model: _ctx.rut,
            "onUpdate:model": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rut) = $event)),
            class: "w-full",
            "has-error": _ctx.hasRutError,
            "error-message": (_ctx.rut.trim().length === 0) ? 'El RUT está vacío' : 'El RUT no es válido. Verifique que no esté mal escrito'
          }, null, 8, ["model", "has-error", "error-message"]),
          _createVNode(_component_TextField, {
            label: "Celular",
            name: "phone",
            type: "string",
            model: _ctx.phone,
            "onUpdate:model": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phone) = $event)),
            class: "w-full",
            "has-error": _ctx.hasError && _ctx.phone.trim().length === 0,
            "error-message": 'El celular está vacío'
          }, null, 8, ["model", "has-error"]),
          _createVNode(_component_TextField, {
            label: "Correo electrónico",
            name: "email",
            type: "string",
            model: _ctx.email,
            "onUpdate:model": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.email) = $event)),
            class: "w-full",
            "has-error": _ctx.hasMailError,
            "error-message": (_ctx.email.trim().length === 0) ? 'El correo electrónico está vacío' : 'Correo electrónico no válido. Verifique que no esté mal escrito'
          }, null, 8, ["model", "has-error", "error-message"]),
          _createVNode(_component_TextField, {
            label: "Contraseña",
            name: "password",
            type: "password",
            model: _ctx.password,
            "onUpdate:model": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.password) = $event)),
            class: "w-full",
            "has-error": _ctx.hasError && _ctx.password.trim().length === 0,
            "error-message": 'La contraseña está vacia'
          }, null, 8, ["model", "has-error"]),
          _createVNode(_component_TextField, {
            label: "Repita contraseña",
            name: "rePassword",
            type: "password",
            model: _ctx.rePassword,
            "onUpdate:model": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.rePassword) = $event)),
            class: "w-full",
            "has-error": _ctx.hasPassError || (_ctx.password.trim().length === 0 && _ctx.hasError),
            "error-message": (_ctx.rePassword.trim().length === 0) ? 'La contraseña está vacia' : 'Las contraseñas no coinciden'
          }, null, 8, ["model", "has-error", "error-message"]),
          _createVNode(_component_AppButton, {
            text: "Registrarse",
            uppercase: true,
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.createAccount())),
            class: "bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 w-full mt-auto"
          })
        ], 32)
      ]),
      _: 1
    }),
    (_ctx.showAlert)
      ? (_openBlock(), _createBlock(_component_Alert, {
          key: 0,
          type: _ctx.alertType,
          message: _ctx.showAlertMessage
        }, null, 8, ["type", "message"]))
      : _createCommentVNode("", true)
  ]))
}